import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { mainUrl } from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete } from "@mui/icons-material";
import {
  deleteElevator,
  getElevators,
} from "../../../api/services/elevatorService";
import { useSnackbar } from "notistack";
import CustomLoading from "../../custom/CustomLoading";
import { formatAddress } from "../../custom/utils";

type DeleteElevatorsProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const DeleteElevators: React.FC<DeleteElevatorsProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  document.body.style.backgroundColor = "#ffffff";

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("deleteElevator")}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    // Funzione per controllare il localStorage e chiamare l'endpoint
    const checkLocalStorage = () => {
      const tmp = localStorage.getItem("zerynth_deleting_elevator");
      if (!tmp) {
        clearInterval(interval); // Pulisce l'interval dopo il primo controllo
        getElevators().then((res) => {
          if (res && res.installations) {
            setElevators([...res.installations]);
          }
          setLoading(false);
        });
      }
    };

    // Esegui la funzione di controllo ogni secondo
    const interval = setInterval(checkLocalStorage, 1000);

    // Esegui il controllo iniziale quando il componente viene montato
    checkLocalStorage();

    // Pulisci l'interval quando il componente viene smontato
    return () => {
      clearInterval(interval);
    };
  }, []); // L'array vuoto come dipendenza indica che questo effetto si verifica solo una volta

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t("deleteElevator")} goBack={() => navigate(-1)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("installationName")}`,
              field: "installation_container_name",
              flex: 1,
            },
            {
              headerName: `${t("systemNumber")}`,
              field: "system_number",
              flex: 1,
            },
            {
              headerName: `${t("systemSerialNumber")}`,
              field: "system_serial_number",
              flex: 1,
            },
            {
              headerName: `${t("address")}`,
              field: "installation_info",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <>{formatAddress(params.row.installation_info)}</>
              ),
            },
            {
              headerName: `${t("installationDate")}`,
              field: "created_at",
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                new Date(params.row.created_at).toLocaleString(),
            },
            {
              headerName: `${t("delete")}`,
              field: "id",
              flex: 1,
              renderCell: (params) => (
                <CustomIconButton
                  confirm
                  confirmQuestion={`${t("deleteElevatorQuestion")}`}
                  type="outlined"
                  icon={<Delete />}
                  tooltip={`${t("delete")}`}
                  onClick={() =>
                    deleteElevator(params.row.id).then((res) => {
                      if (res && !res.err) {
                        setElevators(
                          elevators.filter((e) => e.id !== params.row.id)
                        );
                        enqueueSnackbar(t("elevatorDeletedSuccess"), {
                          variant: "success",
                        });
                      } else {
                        enqueueSnackbar(
                          t("elevatorDeletedError") + " " + res?.err?.message,
                          {
                            variant: "error",
                          }
                        );
                      }
                    })
                  }
                />
              ),
            },
          ]}
          rows={[...elevators]}
        />
      </Grid>
    </Grid>
  );
};

export default DeleteElevators;
