import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { deleteUser, getUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import { formatAddress, greyColor } from "../../custom/utils";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";

type UsersListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string | ((id: string) => string);
  types: (
    | "customers"
    | "manutentori"
    | "responsabili"
    | "installers"
    | "aziende"
    | "admins"
  )[];
};

const UsersList: React.FC<UsersListProps> = ({
  setBreadcrumb,
  title,
  link,
  types,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    // Creo un array di promesse
    const promises = types.map(async (type) => {
      const res = await getUsers(type);
      return res[type] || [];
    });
    // Attendo che tutte le promesse siano risolte
    Promise.all(promises).then((results) => {
      // Unisco tutti i risultati in un unico array
      const tmp = results.reduce((acc, result) => [...acc, ...result], []);
      setUsers(tmp);
      setLoading(false);
    });
  }, []);

  const columns =
    types.length > 1
      ? [
          {
            headerName: `${t("email")}`,
            field: "email",
            flex: 1,
          },
          { headerName: `${t("name")}`, field: "name", flex: 1 },
          {
            headerName: `${t("role")}`,
            field: "roles",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <>
                {params.row.roles && params.row.roles.length > 0 ? (
                  <CustomTag
                    label={
                      params.row.roles[0] === "customer"
                        ? t("customer")
                        : params.row.roles[0] === "responsabile"
                        ? t("mantainerResponsible")
                        : params.row.roles[0] === "installer"
                        ? t("installer")
                        : params.row.roles[0] === "admin"
                        ? t("admin")
                        : params.row.roles[0] === "manutentore"
                        ? t("mantainer")
                        : params.row.roles[0]
                    }
                    type="filled"
                    color={greyColor}
                  />
                ) : (
                  "N/A"
                )}
              </>
            ),
          },
          {
            headerName: `${t("onlyAddress")}`,
            field: "user_info.address",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <>{formatAddress(params.row.user_info)}</>
            ),
          },
          {
            headerName: `${t("vat")}`,
            field: "user_info.zip_code",
            flex: 0.7,
            renderCell: (params: GridRenderCellParams) => (
              <> {params?.row?.user_info?.zip_code || "N/A"}</>
            ),
          },
          {
            headerName: `${t("phone")}`,
            field: "user_info.phone",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <> {params?.row?.user_info?.phone || "N/A"}</>
            ),
          },
          {
            headerName: ``,
            field: "uid",
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
              <>
                <CustomIconButton
                  tooltip={`${t("edit")}`}
                  icon={<Edit />}
                  type="outlined"
                  onClick={() =>
                    typeof link === "string"
                      ? navigate(link)
                      : navigate(link(params.row.uid))
                  }
                />
                <CustomIconButton
                  tooltip={`${t("delete")}`}
                  confirm
                  icon={<Delete />}
                  type="outlined"
                  onClick={() => {
                    deleteUser(params.row.uid).then((res: any) => {
                      if (res && !res.err) {
                        enqueueSnackbar(t("userDeletedSuccess"), {
                          variant: "success",
                        });
                        window.location.reload();
                      } else {
                        enqueueSnackbar(
                          `${t("userDeletedError")} ${res?.err?.message}`,
                          {
                            variant: "error",
                          }
                        );
                      }
                    });
                  }}
                />
              </>
            ),
          },
        ]
      : [
          {
            headerName: `${t("email")}`,
            field: "email",
            flex: 1,
          },
          { headerName: `${t("name")}`, field: "name", flex: 1 },
          {
            headerName: `${t("onlyAddress")}`,
            field: "user_info.address",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <>{formatAddress(params.row.user_info)}</>
            ),
          },
          {
            headerName: `${t("vat")}`,
            field: "user_info.zip_code",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <> {params?.row?.user_info?.zip_code || "N/A"}</>
            ),
          },
          {
            headerName: `${t("phone")}`,
            field: "user_info.phone",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <> {params?.row?.user_info?.phone || "N/A"}</>
            ),
          },
          {
            headerName: ``,
            field: "uid",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
              <>
                <CustomIconButton
                  tooltip={`${t("edit")}`}
                  icon={<Edit />}
                  type="outlined"
                  onClick={() =>
                    typeof link === "string"
                      ? navigate(link)
                      : navigate(link(params.row.uid))
                  }
                />
                <CustomIconButton
                  tooltip={`${t("delete")}`}
                  confirm
                  icon={<Delete />}
                  type="outlined"
                  onClick={() => {
                    deleteUser(params.row.uid).then((res: any) => {
                      if (res && !res.err) {
                        enqueueSnackbar(t("userDeletedSuccess"), {
                          variant: "success",
                        });
                        window.location.reload();
                      } else {
                        enqueueSnackbar(
                          `${t("userDeletedError")} ${res?.err?.message}`,
                          {
                            variant: "error",
                          }
                        );
                      }
                    });
                  }}
                />
              </>
            ),
          },
        ];

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable columns={columns} rows={users} />
      </Grid>
    </Grid>
  );
};

export default UsersList;
