import { Grid, Typography } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import CustomSelect from "../../custom/CustomSelect";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import { getUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import { assignBoardType } from "../../../api/services/allocatorService";
import { useSnackbar } from "notistack";
import CustomTag from "../../custom/CustomTag";
import { formatAddress, greyColor } from "../../custom/utils";

type AssignBoardProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const AssignBoard: React.FC<AssignBoardProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("assignBoardType")}
      </Typography>,
    ]);
  }, []);

  const [users, setUsers] = useState<any[]>([]);
  const [boards, setBoards] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  useEffect(() => {
    getUsers("admins").then((res) => {
      if (res && res.admins) {
        setUsers(
          res.admins.map((a: any) => {
            return { label: a.name, value: a.uid };
          })
        );
        let tmp: any[] = [];
        res.admins.map((a: any) => {
          (a.board_assigned || []).map((b: string) =>
            tmp.push({
              adminEmail: a.email,
              adminName: a.name,
              adminUid: a.uid,
              boardType: b,
            })
          );
        });
        setBoards(
          [...tmp].reduce((acc, curr) => {
            const existingAdmin = acc.find(
              (item: any) => item.adminUid === curr.adminUid
            );
            if (existingAdmin) {
              existingAdmin.boardType.push(curr.boardType);
            } else {
              acc.push({
                adminEmail: curr.adminEmail,
                adminName: curr.adminName,
                adminUid: curr.adminUid,
                boardType: [curr.boardType],
                user_info: res.admins.find((a: any) => a.uid === curr.adminUid)
                  .user_info,
              });
            }
            return acc;
          }, [])
        );
      }
      setLoading(false);
    });
  }, [reload]);

  const [values, setValues] = useState({
    board_type: "",
    admin: "",
  });

  const handleSubmit = () => {
    assignBoardType(values.admin, values.board_type).then((res: any) => {
      if (res && !res.err) {
        enqueueSnackbar(t("assignBoardSuccess"), {
          variant: "success",
        });
        setReload(!reload);
      } else {
        enqueueSnackbar(t("assignBoardError") + " " + res?.err?.message, {
          variant: "error",
        });
      }
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("assignBoardType")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md>
                  <CustomSelect
                    label={`${t("admin")}`}
                    value={values.admin}
                    handleChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        admin: e.target.value,
                      }))
                    }
                    options={users}
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomSelect
                    label={`${t("boardType")}`}
                    value={values.board_type}
                    handleChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        board_type: e.target.value,
                      }))
                    }
                    options={[
                      { label: t("elevate"), value: "elevate" },
                      { label: t("retrofit"), value: "retrofit" },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              confirm
              fullWidth
              type="outlined"
              label={t("reset")}
              onClick={() => setValues({ admin: "", board_type: "" })}
            />,
            <CustomButton
              fullWidth
              type="contained"
              label={t("ok")}
              onClick={handleSubmit}
            />,
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            { headerName: `${t("name")}`, field: "adminName", flex: 1 },
            { headerName: `${t("email")}`, field: "adminEmail", flex: 1 },
            {
              headerName: `${t("address")}`,
              field: "user_info.address",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => {
                return <>{formatAddress(params.row.user_info)}</>;
              },
            },
            {
              headerName: `${t("boardType")}`,
              field: "boardType",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <Grid container spacing={2} justifyContent="center">
                  {(params.row.boardType || []).map((b: string) => {
                    return (
                      <Grid item>
                        <CustomTag
                          type="filled"
                          color={greyColor}
                          label={t(b)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ),
            },
          ]}
          rows={[...boards]}
        />
      </Grid>
    </Grid>
  );
};

export default AssignBoard;
