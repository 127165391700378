import React, { useCallback, useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import { elevatorListToUpdateUrl, mainUrl } from "../costants";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomSelect from "../../custom/CustomSelect";
import CustomText from "../../custom/CustomText";
import CustomDivider from "../../custom/CustomDivider";
import {
  getAlarmsCalibration,
  updateAlarmsCalibration,
} from "../../../api/services/elevatorService";
import { useSnackbar } from "notistack";
import CustomInput from "../../custom/CustomInput";

type AlarmsThresholdsProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const AlarmsThresholds: React.FC<AlarmsThresholdsProps> = ({
  setBreadcrumb,
}) => {
  const { id } = useParams(); //device-id
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="1" to={elevatorListToUpdateUrl}>
        {t("updateDevice")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("alarmsThresholds")}
      </Typography>,
    ]);
  }, []);

  const [maximumEnginePower, setMaximumEnginePower] = useState({
    enabled: false,
    level: "",
    id: "",
  });
  const [standbyPowerLimit, setStandbyPowerLimit] = useState({
    enabled: false,
    level: "",
    id: "",
  });
  const [ridesLimit, setRidesLimit] = useState<any>({
    enabled: false,
    upperLimit: 0,
    level: "",
    id: "",
  });
  useEffect(() => {
    getAlarmsCalibration(id!).then((res: any) => {
      if (res && res.alarms) {
        res.alarms.forEach((alarm: any) => {
          if (alarm.name === "p_max_motor") {
            setMaximumEnginePower({
              enabled: alarm?.config?.enabled || false,
              level: alarm?.config?.level || "",
              id: alarm.id,
            });
          }
          if (alarm.name === "p_lim_standby") {
            setStandbyPowerLimit({
              enabled: alarm?.config?.enabled || false,
              level: alarm?.config?.level || "",
              id: alarm.id,
            });
          }
          if (alarm.name === "rides_limit") {
            setRidesLimit({
              enabled: alarm?.config?.enabled || false,
              upperLimit: Number(alarm?.config?.upper_threshold || 0),
              level: alarm?.config?.level || "",
              id: alarm.id,
            });
          }
        });
      }
    });
  }, []);

  const handleSubmit = useCallback(() => {
    updateAlarmsCalibration({
      installation_id: id!,
      alarms_array_update: [
        {
          config_id: maximumEnginePower.id,
          config: {
            enabled: maximumEnginePower.enabled,
            level: maximumEnginePower.level,
          },
        },
        {
          config_id: standbyPowerLimit.id,
          config: {
            enabled: standbyPowerLimit.enabled,
            level: standbyPowerLimit.level,
          },
        },
        {
          config_id: ridesLimit.id,
          config: {
            enabled: ridesLimit.enabled,
            level: ridesLimit.level,
            upper_threshold: String(ridesLimit.upperLimit),
          },
        },
      ],
    }).then((res: any) => {
      if (res && !res.err) {
        enqueueSnackbar(t("alarmsThresholdsUpdatedSuccess"), {
          variant: "success",
        });
        navigate(-1);
      } else {
        enqueueSnackbar(
          `${t("alarmsThresholdsUpdatedError")} ${res?.err?.message}`,
          {
            variant: "error",
          }
        );
      }
    });
  }, [maximumEnginePower, standbyPowerLimit, ridesLimit]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("alarmsThresholds")}
          goBack={() => navigate(elevatorListToUpdateUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomText
                  label={t("maximumEnginePower") + " (W)"}
                  type="h6"
                />
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item>
                  <Switch
                    checked={maximumEnginePower.enabled}
                    onChange={(e) =>
                      setMaximumEnginePower((prev: any) => ({
                        ...prev,
                        enabled: e.target.checked,
                      }))
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomText
                    label={
                      maximumEnginePower.enabled ? t("enabled") : t("disabled")
                    }
                    type="body1"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("level")}`}
                  value={maximumEnginePower.level}
                  handleChange={(e) =>
                    setMaximumEnginePower((prev: any) => ({
                      ...prev,
                      level: e.target.value,
                    }))
                  }
                  options={[
                    { label: `${t("critical")}`, value: "critical" },
                    { label: `${t("warning")}`, value: "warning" },
                    { label: `${t("low")}`, value: "low" },
                  ]}
                  disabled={!maximumEnginePower.enabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDivider />
              </Grid>
              <Grid item xs={12}>
                <CustomText label={t("standbyPowerLimit") + " (W)"} type="h6" />
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item>
                  <Switch
                    checked={standbyPowerLimit.enabled}
                    onChange={(e) =>
                      setStandbyPowerLimit((prev: any) => ({
                        ...prev,
                        enabled: e.target.checked,
                      }))
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomText
                    label={
                      standbyPowerLimit.enabled ? t("enabled") : t("disabled")
                    }
                    type="body1"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("level")}`}
                  value={standbyPowerLimit.level}
                  handleChange={(e) =>
                    setStandbyPowerLimit((prev: any) => ({
                      ...prev,
                      level: e.target.value,
                    }))
                  }
                  options={[
                    { label: `${t("critical")}`, value: "critical" },
                    { label: `${t("warning")}`, value: "warning" },
                    { label: `${t("low")}`, value: "low" },
                  ]}
                  disabled={!standbyPowerLimit.enabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDivider />
              </Grid>
              <Grid item xs={12}>
                <CustomText label={t("ridesLimit")} type="h6" />
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item>
                  <Switch
                    checked={ridesLimit.enabled}
                    onChange={(e) =>
                      setRidesLimit((prev: any) => ({
                        ...prev,
                        enabled: e.target.checked,
                      }))
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomText
                    label={ridesLimit.enabled ? t("enabled") : t("disabled")}
                    type="body1"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomSelect
                  label={`${t("level")}`}
                  value={ridesLimit.level}
                  handleChange={(e) =>
                    setRidesLimit((prev: any) => ({
                      ...prev,
                      level: e.target.value,
                    }))
                  }
                  options={[
                    { label: `${t("critical")}`, value: "critical" },
                    { label: `${t("warning")}`, value: "warning" },
                    { label: `${t("low")}`, value: "low" },
                  ]}
                  disabled={!ridesLimit.enabled}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomInput
                  label={`${t("maxNumberRides")}`}
                  value={ridesLimit.upperLimit}
                  handleChange={(e) =>{
                    if(Number(e.target.value) < 0) {
                      setRidesLimit((prev: any) => ({
                        ...prev,
                        upperLimit: 0,
                      }))
                    } else {
                      setRidesLimit((prev: any) => ({
                        ...prev,
                        upperLimit: Number(e.target.value),
                      }))
                    }
                    return
                  }}
                  type="number"
                  disabled={!ridesLimit.enabled}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() => {
                setMaximumEnginePower((prev) => {
                  return {
                    enabled: false,
                    level: "",
                    id: prev.id,
                  };
                });
                setStandbyPowerLimit((prev) => {
                  return {
                    enabled: false,
                    level: "",
                    id: prev.id,
                  };
                });
              }}
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default AlarmsThresholds;
