import { Typography } from "@mui/material";
import React from "react";

type CustomTextProps = {
  label: string;
  type:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2";
};

const CustomText: React.FC<CustomTextProps> = ({ label, type }) => {
  return <Typography variant={type}>{label}</Typography>;
};

export default CustomText;
