import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { mainUrl } from "../costants";
import { useNavigate } from "react-router-dom";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link } from "react-router-dom";
import {
  getElevators,
  getExportInfo,
} from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import { GetExportInfoRequest } from "../../../api/requests/elevatorService";
import CustomSelect from "../../custom/CustomSelect";

type DownloadDataProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const DownloadData: React.FC<DownloadDataProps> = ({ setBreadcrumb }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="4">
        {t("dataHistory")}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  useEffect(() => {
    getElevators().then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoading(false);
    });
  }, []);

  // Funzione per formattare la data nel formato YYYY-MM-DD
  function formatDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const downloadFile = useCallback((url: string) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    /*
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("RESP DOWNLOAD FILE: ", res);
        if (res && !res.err) {
          console.log("RESP DOWNLOAD FILE PATH: ", res?.path);
          const link = document.createElement("a");
          link.href = url;
          document.body.appendChild(link);
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          document.body.removeChild(link);
        } else {
          enqueueSnackbar( t("downloadErrror"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
      */
  }, []);

  const [values, setValues] = useState({
    elevator: "",
    start_date: formatDate(
      new Date(new Date().setDate(new Date().getDate() - 6))
    ),
    end_date: formatDate(new Date()),
  });

  const handleSubmit = useCallback(() => {
    if (values && values.elevator) {
      const first = new Date(values.start_date);
      const second = new Date(values.end_date);
      let differenceInTime = second.getTime() - first.getTime();
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (differenceInDays <= 6) {
        const info: GetExportInfoRequest = {
          tag: "d",
          start: new Date(values.start_date).toISOString().split("T")[0],
          end: new Date(values.end_date).toISOString().split("T")[0],
          entity_id: values.elevator,
        };
        getExportInfo(info)
          .then((resp) => {
            console.log("RESP EXPORT INFO: ", resp);
            console.log("RESP EXPORT INFO URL: ", resp?.url);
            if (resp && resp.url) {
              downloadFile(resp.url);
            } else if (resp && resp.err) {
              enqueueSnackbar(resp?.err?.message || t("downloadErrror"), {
                variant: "error",
                preventDuplicate: true,
              });
            }
          })
          .catch((e) => console.log("error: ", e));
      } else {
        enqueueSnackbar(t("sevenDaysError"), {
          variant: "error",
          preventDuplicate: true,
        });
      }
    } else {
      enqueueSnackbar(t("emptyElevatorError"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [values]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t("dataHistory")} goBack={() => navigate(-1)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomSelect
                  label={`${t("selectElevator")}`}
                  value={values.elevator}
                  handleChange={(e: any) => {
                    setValues({ ...values, elevator: e.target.value });
                  }}
                  options={elevators.map((e) => ({
                    value: e.id,
                    label: `${e.name} - ${e?.system_number} (${e.id})`,
                  }))}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label={t("startDate")}
                  type="date"
                  value={values.start_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, start_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label={t("endDate")}
                  type="date"
                  value={values.end_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, end_date: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              type="outlined"
              label={t("reset")}
              onClick={() => {
                setValues({ elevator: "", start_date: "", end_date: "" });
              }}
              confirm
              confirmQuestion={`${t("question")}`}
            />,
            <CustomButton
              fullWidth
              type="contained"
              label={t("downloadCsv")}
              onClick={handleSubmit}
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default DownloadData;
