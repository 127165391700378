import { isDev } from "../../components/pages/costants";

// METODI HTTP
export const methodPut = "PUT";
export const methodGet = "GET";
export const methodPost = "POST";
export const methodDelete = "DELETE";

export const workspaceId = /*isDev ? "wks-8no51b6rc9x7" :*/ "wks-8oglsckydf5t";
export const fleetId = /*isDev ? "flt-8no51b6ytyyk" :*/ "flt-8oglscl5v476";
export const firmwareId = /*isDev ? "fmw-8o9aiffxhumr" : */ "fmw-8oyiasiwprz0";
export const apiKey = isDev
  ? "8jpJvjdG3J.3YpVD17Rt6wFVD-NAN7EPxM7nhVax8GK2fvIhDkX58qRZpFIOqFGmp-QJ7XIIwxP"
  : window._env_.ZDM_APIKEY!;
export const azApiKey = "Zerynth-241f1fcc39a64696bf3f9bfc8f54ea7c";

// FETCH
export const verticalFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    credentials: "include",
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

export const ZDMFetch = (
  method: string,
  url: string,
  param?: string,
  auth?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Fetch error:", error); // Log dell'errore
      throw error; // Propaga l'errore
    });
};

export const binaryFetch = (method: string, url: string, param?: string) => {
  const requestOptions: RequestInit = {
    method: method,
    credentials: "include" as RequestCredentials,
    headers: {
      "X-API-KEY": apiKey,
    },
    body: param,
  };
  const mockRequestOptions: RequestInit = {
    method: method,
    body: param,
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/json",
      "X-API-KEY": apiKey,
    },
  };
  return fetch(url, isDev ? mockRequestOptions : requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.arrayBuffer();
      }
      throw new Error(response.statusText);
    })
    .catch((error) => console.log(error));
};

export const azFetch = (
  method: string,
  url: string,
  param?: string,
  auth?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-KEY": azApiKey,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

export const elevateFetch = (
  method: string,
  url: string,
  body?: string,
  token?: string,
  cookie?: string
): Promise<any> => {
  let header: any = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };
  if (token && cookie) {
    console.log("TOKEN", token);
    console.log("COOKIE", cookie);
    header = {
      ...header,
      Authorization: `Bearer ${token}`,
      Cookie: cookie,
    };
  }
  console.log("HEADER", header);
  return fetch(url, {
    method: method,
    body: body,
    headers: { ...header },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};
