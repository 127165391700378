import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import {
  deleteUser,
  getCompanyByAdmin,
  getUsers,
} from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomSelect from "../../custom/CustomSelect";
import CustomList from "../../custom/CustomList";
import CustomCard from "../../custom/CustomCard";
import { darkColor, formatAddress } from "../../custom/utils";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";

type InstallationsListToUpdateProps = {
  title: string;
  adminFilter: boolean;
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  link: string | ((id: string) => string);
};

const InstallationsListToUpdate: React.FC<InstallationsListToUpdateProps> = ({
  title,
  adminFilter,
  setBreadcrumb,
  link,
}) => {
  document.body.style.backgroundColor = "#ffffff";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading1, setLoading1] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [companies, setCompanies] = useState<any[]>([]);
  const [admins, setAdmins] = useState<any[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string>("");

  useEffect(() => {
    if (adminFilter) {
      getUsers("admins").then((res) => {
        if (res && res.admins) {
          setAdmins([...res.admins]);
          if (res.admins.length > 0) {
            setSelectedAdmin(res.admins[0].uid);
          }
        }
        setLoading2(false);
      });
    } else {
      setLoading2(false);
    }
  }, []);

  useEffect(() => {
    if ((adminFilter && selectedAdmin) || !adminFilter) {
      getCompanyByAdmin(selectedAdmin).then((res) => {
        if (res && res.aziende) {
          setCompanies([...res.aziende]);
        } else {
          setCompanies([]);
        }
        setLoading1(false);
      });
    } else {
      setLoading1(false);
    }
  }, [selectedAdmin]);

  if (loading1 || loading2) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={adminFilter ? t("companyOverview") : t(title)}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      {adminFilter && (
        <Grid item xs={12}>
          <Typography variant="h5" style={{ color: darkColor }}>
            {t("selectAdminCompany")}
          </Typography>
        </Grid>
      )}
      {adminFilter && (
        <Grid item xs={12}>
          <CustomSelect
            // label={t("admin")}
            value={selectedAdmin}
            handleChange={(e) => setSelectedAdmin(e.target.value)}
            options={admins.map((a) => ({
              label: `${a.name}`,
              value: a.uid,
            }))}
          />
        </Grid>
      )}
      {adminFilter && (
        <Grid item xs={12}>
          <CustomCard
            header={{ visible: true, title: `${t("registry")}` }}
            content={
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomList
                    listElement={[
                      {
                        primaryText: t("email"),
                        action: admins.find((a) => a.uid === selectedAdmin)
                          ?.email,
                      },
                      {
                        primaryText: t("phone"),
                        action: admins.find((a) => a.uid === selectedAdmin)
                          ?.user_info?.phone,
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomList
                    listElement={[
                      {
                        primaryText: t("boardsAssigned"),
                        action:
                          (
                            admins.find((a) => a.uid === selectedAdmin)
                              ?.board_assigned || []
                          )
                            .join(",")
                            .replace("retrofit", "retrofitting") || "N/A",
                      },
                      {
                        primaryText: t("creationDate"),
                        action: new Date(
                          admins.find((a) => a.uid === selectedAdmin).created_at
                        ).toLocaleString(),
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomTable
          columns={
            title !== "updateCompany"
              ? [
                  {
                    headerName: `${t("email")}`,
                    field: "email",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => {
                      return (
                        <Link
                          style={{ color: "black" }}
                          to={
                            typeof link === "string"
                              ? link
                              : link(params.row.uid)
                          }
                        >
                          {params.row.email}
                        </Link>
                      );
                    },
                  },
                  {
                    headerName: `${t("businessName")}`,
                    field: "name",
                    flex: 1,
                  },
                  {
                    headerName: `${t("address")}`,
                    field: "user_info.address",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <>{formatAddress(params.row.user_info)}</>
                    ),
                  },
                  {
                    headerName: `${t("phone")}`,
                    field: "user_info.phone",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <> {params?.row?.user_info?.phone || "N/A"}</>
                    ),
                  },
                  {
                    headerName: `${t("vat")}`,
                    field: "user_info.zip_code",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <> {params?.row?.user_info?.zip_code || "N/A"}</>
                    ),
                  },
                ]
              : [
                  {
                    headerName: `${t("email")}`,
                    field: "email",
                    flex: 1,
                  },
                  {
                    headerName: `${t("businessName")}`,
                    field: "name",
                    flex: 1,
                  },
                  {
                    headerName: `${t("address")}`,
                    field: "user_info.address",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <>{formatAddress(params.row.user_info)}</>
                    ),
                  },
                  {
                    headerName: `${t("phone")}`,
                    field: "user_info.phone",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <> {params?.row?.user_info?.phone || "N/A"}</>
                    ),
                  },
                  {
                    headerName: `${t("vat")}`,
                    field: "user_info.zip_code",
                    flex: 1,
                    renderCell: (params: GridRenderCellParams) => (
                      <> {params?.row?.user_info?.zip_code || "N/A"}</>
                    ),
                  },
                  {
                    headerName: ``,
                    field: "uid",
                    flex: 0.5,
                    renderCell: (params: GridRenderCellParams) => (
                      <>
                        <CustomIconButton
                          icon={<Edit />}
                          tooltip={`${t("edit")}`}
                          type="outlined"
                          onClick={() => {
                            console.log("update");
                            typeof link === "string"
                              ? navigate(link)
                              : navigate(link(params.row.uid));
                          }}
                        />
                        <CustomIconButton
                          confirm
                          confirmQuestion={`${t("deleteCompanyQuestion")}`}
                          icon={<Delete />}
                          tooltip={`${t("delete")}`}
                          type="outlined"
                          onClick={() => {
                            deleteUser(params.row.uid).then((res: any) => {
                              if (res && !res.err) {
                                enqueueSnackbar(t("companyDeletedSuccess"), {
                                  variant: "success",
                                });
                                window.location.reload();
                              } else {
                                enqueueSnackbar(
                                  `${t("companyDeletedError")} ${
                                    res?.err?.message
                                  }`,
                                  {
                                    variant: "error",
                                  }
                                );
                              }
                            });
                          }}
                        />
                      </>
                    ),
                  },
                ]
          }
          rows={companies}
        />
      </Grid>
    </Grid>
  );
};

export default InstallationsListToUpdate;
