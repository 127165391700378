import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getCompanyByAdmin, getUsers } from "../../../api/services/userService";
import CustomLoading from "../../custom/CustomLoading";
import CustomSelect from "../../custom/CustomSelect";
import CustomCard from "../../custom/CustomCard";
import {getCompanyElevators, getElevators} from "../../../api/services/elevatorService";
import CustomButton from "../../custom/CustomButton";
import CustomText from "../../custom/CustomText";

type OverviewProps = {
  title: string;
  superadmin: boolean;
  admin: boolean;
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  link: string | ((id: string) => string);
};

const Overview: React.FC<OverviewProps> = ({
  title,
  superadmin,
  admin,
  setBreadcrumb,
  link,
}) => {
  document.body.style.backgroundColor = "#ffffff";

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [loading1, setLoading1] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [loading3, setLoading3] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [admins, setAdmins] = useState<any[]>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedElevator, setSelectedElevator] = useState<string>("");
  useEffect(() => {
    if (superadmin) {
      getUsers("admins").then((res) => {
        if (res && res.admins) {
          setAdmins([...res.admins]);
          if (res.admins.length > 0) {
            setSelectedAdmin(res.admins[0].uid);
          }
        }
        setLoading2(false);
      });
    } else {
      setLoading2(false);
    }
  }, []);

  useEffect(() => {
    if ((superadmin && selectedAdmin) || !superadmin) {
      getCompanyByAdmin(selectedAdmin).then((res) => {
        if (res && res.aziende) {
          setCompanies([...res.aziende]);
        } else {
          setCompanies([]);
        }
        setLoading1(false);
      });
    } else {
      setLoading1(false);
    }
  }, [selectedAdmin]);


  useEffect(() => {
    if (selectedCompany) {
      getCompanyElevators(selectedCompany).then((res: any) => {
        if (res && res.installations) {
          setElevators([...res.installations]);
        } else {
          setElevators([]);
        }
        setLoading3(false);
      });
    } else {
      setLoading3(false);
    }
  }, [selectedCompany]);

  const [visible, setVisible] = useState<boolean>(false);

  if (loading1 || loading2 || loading3) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={`${t(title)}`} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomText label={`${t("selectCompany")}`} type="subtitle1" />
              </Grid>
              {superadmin && (
                <Grid item xs={12} md>
                  <CustomSelect
                    label={`${t("admin")}`}
                    value={selectedAdmin}
                    handleChange={(e) => {
                      setSelectedAdmin(e.target.value);
                      setSelectedCompany("");
                      setSelectedElevator("");
                      setCompanies([]);
                      setElevators([]);
                      setVisible(false);
                    }}
                    options={admins.map((a) => ({
                      label: `${a.name}`,
                      value: a.uid,
                    }))}
                  />
                </Grid>
              )}
              {(superadmin || admin) && (
                <Grid item xs={12} md>
                  <CustomSelect
                    label={`${t("company")}`}
                    value={selectedCompany}
                    handleChange={(e) => {
                      setSelectedCompany(e.target.value);
                      setSelectedElevator("");
                      setElevators([]);
                      setVisible(true);
                    }}
                    options={companies.map((a) => ({
                      label: `${a.name}`,
                      value: a.uid,
                    }))}
                    disabled={
                      admin ? false : !(superadmin && selectedAdmin !== "")
                    }
                  />
                </Grid>
              )}
              {selectedCompany && visible && (
                <Grid item xs={12}>
                  <CustomText
                    label={`${t("selectElevator")}`}
                    type="subtitle1"
                  />
                </Grid>
              )}
              {selectedCompany && visible && (
                <Grid item xs={12}>
                  <CustomSelect
                    label=""
                    value={selectedElevator}
                    handleChange={(e) => setSelectedElevator(e.target.value)}
                    options={elevators.map((a) => ({
                      label: `${a.name}`,
                      value: a.id,
                    }))}
                  />
                </Grid>
              )}
            </Grid>
          }
          actions={
            selectedElevator
              ? [
                  <CustomButton
                    confirm
                    fullWidth
                    type="outlined"
                    label={t("reset")}
                    onClick={() => {
                      setSelectedElevator("");
                      setSelectedCompany("");
                      setSelectedAdmin("");
                    }}
                  />,
                  <CustomButton
                    fullWidth
                    type="contained"
                    label={t("ok")}
                    onClick={() => {
                      typeof link === "string"
                        ? navigate(link)
                        : navigate(link(selectedElevator));
                    }}
                  />,
                ]
              : []
          }
        />
      </Grid>
    </Grid>
  );
};

export default Overview;
