import { Grid, Typography } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import CustomTable from "../../custom/CustomTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { getElevators } from "../../../api/services/elevatorService";
import { getJobStatus } from "../../../api/services/configuratorService";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomSelect from "../../custom/CustomSelect";
import CustomInput from "../../custom/CustomInput";
import { useSnackbar } from "notistack";

type MaintenanceListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const MaintenanceList: React.FC<MaintenanceListProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("interventionsHistoryBackupBattery")}
      </Typography>,
    ]);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [jobs, setJobs] = useState<any[]>([]);

  const checkJobTitle = (job: string) => {
    switch (job) {
      case "@change_speed":
        return "Modifica velocità";
      case "@disable_batt_test":
        return "Modifica test periodico";
      case "@local_test":
        return "Attivazione test immediato";
      case "@change_test_params":
        return "Configurazione test periodico";
      default:
        return job;
    }
  };

  const handleSearch = (elevator?: any, allElevators?: any[]) => {
    if (new Date(values.start_date) < new Date(values.end_date)) {
      const tmpEl = elevator ? elevator : values.elevatorId;
      let promises: Promise<any>[] = [];
      tmpEl.forEach((i: any) => {
        promises.push(getJobStatus(i).then((r: any) => r?.events || []));
      });
      Promise.all(promises).then((results) => {
        let tmpJobs: any[] = [];
        results.forEach((jobs, index) => {
          const ii = (allElevators || []).find((el) => el.id === tmpEl[index]);
          const tmp = [...jobs].map((obj: any) => ({
            ...obj,
            id: ii.id,
            name: ii.name,
            installation_container_name: ii.installation_container_name,
            system_number: ii.system_number,
            system_serial_number: ii.system_serial_number,
            installation_info: { ...ii.installation_info },
          }));
          tmpJobs = [...tmpJobs, ...tmp];
          tmpJobs = tmpJobs.map((j) => {
            if (j?.direction === "c2d") {
              return { ...j, status: "sent" };
            } else {
              return { ...j, status: "done" };
            }
          });
        });
        setJobs(
          tmpJobs
            .filter(
              (j) =>
                j?.payload?.key === "@change_speed" ||
                j?.payload?.key === "@disable_batt_test" ||
                j?.payload?.key === "@local_test" ||
                j?.payload?.key === "@change_test_params"
            )
            .filter(
              (j) =>
                new Date(j.ts) >= new Date(values.start_date) &&
                new Date(j.ts) <= new Date(values.end_date)
            )
        );
        setLoading(false);
      });
    } else {
      enqueueSnackbar(t("startEndDate"), { variant: "error" });
    }
  };

  function formatDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [values, setValues] = useState<any>({
    elevatorId: [],
    start_date: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    end_date: formatDate(new Date()),
  });

  const [loadingElevators, setLoadingElevators] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  useEffect(() => {
    getElevators().then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
        setValues({
          ...values,
          elevatorId: res.installations.map((el: any) => el.id),
        });
        handleSearch(
          res.installations.map((el: any) => el.id),
          res.installations
        );
      }
      setLoadingElevators(false);
    });
  }, []);

  if (loading || loadingElevators) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("interventionsHistoryBackupBattery")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CustomSelect
                  multiple
                  value={values.elevatorId}
                  label={`${t("selectElevator")}`}
                  handleChange={(e: any) =>
                    setValues({
                      ...values,
                      elevatorId: e.target.value,
                    })
                  }
                  options={elevators.map((el) => ({
                    value: el.id,
                    label: `${el.name}`,
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label={t("startDate")}
                  type="date"
                  value={values.start_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, start_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label={t("endDate")}
                  type="date"
                  value={values.end_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, end_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButton
                  label={t("reset")}
                  onClick={() => {
                    setValues({
                      elevatorId: [],
                      start_date: formatDate(
                        new Date(new Date().setDate(new Date().getDate() - 30))
                      ),
                      end_date: formatDate(new Date()),
                    });
                  }}
                  type="outlined"
                  confirm
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButton
                  label={t("search")}
                  onClick={() => handleSearch(undefined, elevators)}
                  type="contained"
                  fullWidth
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            {
              headerName: `${t("date")}`,
              field: "ts",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => {
                return <>{new Date(params.row.ts).toLocaleString()}</>;
              },
            },
            {
              headerName: `${t("name")}`,
              field: "name", //"installation_container_name",
              flex: 1,
            },
            {
              headerName: `${t("systemNumber")}`,
              field: "system_number",
              flex: 1,
            },
            {
              headerName: `${t("systemSerialNumber")}`,
              field: "system_serial_number",
              flex: 1,
            },
            {
              headerName: `${t("job")}`,
              field: "payload.key",
              flex: 1.5,
              renderCell: (params: GridRenderCellParams) => (
                <>{checkJobTitle(params.row.payload.key)}</>
              ),
            },
            {
              headerName: `${t("status")}`,
              field: "status",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => {
                return (
                  <CustomTag
                    label={t(params.row?.status)}
                    type="filled"
                    color={params.row?.status === "done" ? "green" : "orange"}
                  />
                );
              },
            },
            {
              headerName: `${t("note")}`,
              field: "value",
              flex: 2,
              renderCell: (params: GridRenderCellParams) => {
                const tmp = params.row?.payload?.value?.args;
                return (
                  <Grid container>
                    {tmp && tmp.hour && (
                      <Grid item xs={6}>
                        Ore: {tmp.hour}
                      </Grid>
                    )}
                    {tmp && tmp.speed && (
                      <Grid item xs={6}>
                        Nuova velocità inserita: {tmp.speed} m/s
                      </Grid>
                    )}
                    {tmp && tmp.duration && (
                      <Grid item xs={6}>
                        Durata: {tmp.duration} s
                      </Grid>
                    )}
                    {tmp && tmp.week_day && (
                      <Grid item xs={6}>
                        Giorno: {tmp.week_day}
                      </Grid>
                    )}
                    {tmp && (tmp.batt_test === 0 || tmp.batt_test === 1) && (
                      <Grid item xs={6}>
                        {tmp.batt_test === 0
                          ? "Test periodico abilitato"
                          : "Test periodico disabilitato"}
                      </Grid>
                    )}
                    {tmp && tmp.frequency && (
                      <Grid item xs={6}>
                        Frequenza:{" "}
                        {tmp.frequency === "week"
                          ? "settimanale"
                          : tmp.frequency === "month"
                          ? "mensile"
                          : tmp.frequency}
                      </Grid>
                    )}
                  </Grid>
                );
              },
            },
          ]}
          rows={jobs}
        />
      </Grid>
    </Grid>
  );
};

export default MaintenanceList;
