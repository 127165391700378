import {
  alarmsPageUrl,
  companyListToChartUrl,
  mainUrl,
  createCompanyUrl,
  companyListToUpdateUrl,
  paramsElevatorUrl,
  createElevatorUrl,
  elevatorListToUpdateUrl,
  elevatorListToChartUrl,
  createMantainerUrl,
  assignMantainerUrl,
  mantainerListUrl,
  createUsersUrl,
  usersListUrl,
  assignUserUrl,
  deleteElevatorUrl,
  maintenanceListUrl,
  maintenanceHistoryUrl,
  createAdminUrl,
  adminListUrl,
  assignBoardTypeUrl,
  testBatteriaTamponeElevatorUrl,
  azMaintenanceUrl,
  downloadDataUrl,
  azUserMaintenanceUrl,
  wifiConfigurationUrl,
} from "../pages/costants";

export const superadminMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "companyOverview",
    label: t("companyOverview"),
    elements: [],
    link: companyListToChartUrl,
  },
  {
    key: "adminManaging",
    label: t("adminManaging"),
    link: mainUrl,
    elements: [
      {
        key: "createAdmin",
        label: t("createAdmin"),
        link: createAdminUrl,
        elements: [],
      },
      {
        key: "updateAdmin",
        label: t("updateAdmin"),
        link: adminListUrl,
        elements: [],
      },
      {
        key: "assignBoardType",
        label: t("assignBoardType"),
        link: assignBoardTypeUrl,
        elements: [],
      },
    ],
  },
];

export const adminMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "companyOverview",
    label: t("companyOverview"),
    elements: [],
    link: companyListToChartUrl,
  },
  {
    key: "elevatorsHistory",
    label: t("elevatorsHistory"),
    link: mainUrl,
    elements: [
      {
        key: "datas",
        label: t("datas"),
        elements: [],
        link: downloadDataUrl,
      },
      {
        key: "alarms",
        label: t("alarms"),
        elements: [],
        link: alarmsPageUrl,
      },
    ],
  },
  {
    key: "companiesManaging",
    label: t("companiesManaging"),
    link: mainUrl,
    elements: [
      {
        key: "createCompany",
        label: t("createCompany"),
        link: createCompanyUrl,
        elements: [],
      },
      {
        key: "updateCompany",
        label: t("updateCompany"),
        link: companyListToUpdateUrl,
        elements: [],
      },
    ],
  },
];

export const installerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "elevatorOverview",
    label: t("elevatorOverview"),
    elements: [],
    link: elevatorListToChartUrl("all"),
  },
  {
    key: "elevatorsManagement",
    label: t("elevatorsManagement"),
    link: mainUrl,
    elements: [
      {
        key: "createDevice",
        label: t("createDevice"),
        link: createElevatorUrl,
        elements: [],
      },
      {
        key: "deleteElevator",
        label: t("deleteElevator"),
        link: deleteElevatorUrl,
        elements: [],
      },
      {
        key: "wifiConfiguration",
        label: t("wifiConfiguration"),
        link: wifiConfigurationUrl,
        elements: [],
      },
    ],
  },
];

export const customerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "elevatorOverview",
    label: t("elevatorOverview"),
    elements: [],
    link: elevatorListToChartUrl("all"),
  },
  // {
  //   key: "alarms",
  //   label: t("alarms"),
  //   elements: [],
  //   link: alarmsPageUrl,
  // },
  {
    key: "maintenanceHistory",
    label: t("maintenanceHistory"),
    link: azUserMaintenanceUrl,
    elements: [],
  },
];

export const respMantainerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "elevatorOverview",
    label: t("elevatorOverview"),
    elements: [],
    link: elevatorListToChartUrl("all"),
  },
  {
    key: "elevatorsManagement",
    label: t("elevatorsManagement"),
    link: mainUrl,
    elements: [
      {
        key: "updateDevice",
        label: t("updateDevice"),
        link: elevatorListToUpdateUrl,
        elements: [],
      },
      {
        key: "wifiConfiguration",
        label: t("wifiConfiguration"),
        link: wifiConfigurationUrl,
        elements: [],
      },
    ],
  },
  {
    key: "mantainersManagement",
    label: t("mantainersManagement"),
    link: mainUrl,
    elements: [
      {
        key: "createMantainer",
        label: t("createMantainer"),
        link: createMantainerUrl,
        elements: [],
      },
      {
        key: "updateMantainer",
        label: t("updateMantainer"),
        link: mantainerListUrl,
        elements: [],
      },
      {
        key: "assignElevators",
        label: t("assignElevators"),
        link: assignMantainerUrl,
        elements: [],
      },
    ],
  },
  {
    key: "elevatorsHistory",
    label: t("elevatorsHistory"),
    elements: [
      {
        key: "datas",
        label: t("datas"),
        elements: [],
        link: downloadDataUrl,
      },
      {
        key: "alarms",
        label: t("alarms"),
        elements: [],
        link: alarmsPageUrl,
      },
      {
        key: "maintenances",
        label: t("maintenances"),
        elements: [],
        link: maintenanceHistoryUrl,
      },
      {
        key: "manutenzioni",
        label: `${t("manutenzioni")}`,
        link: azMaintenanceUrl,
        elements: [],
      },
    ],
    link: mainUrl,
  },
];

export const companyMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "elevatorOverview",
    label: t("elevatorOverview"),
    elements: [],
    link: elevatorListToChartUrl("all"),
  },
  {
    key: "updateDeleteDevice",
    label: t("updateDeleteDevice"),
    link: elevatorListToUpdateUrl,
    elements: [],
  },
  {
    key: "parameters",
    label: t("updateParameters"),
    link: paramsElevatorUrl,
    elements: [],
  },
  {
    key: "elevatorsHistory",
    label: t("elevatorsHistory"),
    link: mainUrl,
    elements: [
      {
        key: "datas",
        label: t("datas"),
        elements: [],
        link: downloadDataUrl,
      },
      {
        key: "alarms",
        label: t("alarms"),
        elements: [],
        link: alarmsPageUrl,
      },
    ],
  },
  {
    key: "userManaging",
    label: t("userManaging"),
    link: mainUrl,
    elements: [
      {
        key: "createUser",
        label: t("createUser"),
        link: createUsersUrl,
        elements: [],
      },
      {
        key: "updateUser",
        label: t("updateUser"),
        link: usersListUrl,
        elements: [],
      },
      {
        key: "assignElevators",
        label: t("assignElevators"),
        link: assignUserUrl,
        elements: [],
      },
    ],
  },
];

export const mantainerMenuItems: (t: any) => any[] = (t: any) => [
  {
    key: "elevatorOverview",
    label: t("elevatorOverview"),
    elements: [],
    link: elevatorListToChartUrl("all"),
  },
  {
    key: "elevatorsHistory",
    label: t("elevatorsHistory"),
    elements: [
      {
        key: "datas",
        label: t("datas"),
        elements: [],
        link: downloadDataUrl,
      },
      {
        key: "alarms",
        label: t("alarms"),
        elements: [],
        link: alarmsPageUrl,
      },
      {
        key: "maintenances",
        label: t("maintenances"),
        elements: [],
        link: maintenanceListUrl,
      },
      {
        key: "manutenzioni",
        label: `${t("manutenzioni")}`,
        link: azMaintenanceUrl,
        elements: [],
      },
    ],
    link: mainUrl,
  },
  {
    key: "systemConfiguration",
    label: t("systemConfiguration"),
    link: mainUrl,
    elements: [
      {
        key: "parameters",
        label: t("updateParameters"),
        link: paramsElevatorUrl,
        elements: [],
      },
      {
        key: "bufferBatteryTest",
        label: t("bufferBatteryTest"),
        link: testBatteriaTamponeElevatorUrl,
        elements: [],
      },
      {
        key: "wifiConfiguration",
        label: t("wifiConfiguration"),
        link: wifiConfigurationUrl,
        elements: [],
      },
    ],
  },
];
