import React from "react";
import { Alert } from "@mui/material";

type CustomFeedbackProps = {
  label: string;
  type: "error" | "warning" | "info" | "success";
};

const CustomFeedback: React.FC<CustomFeedbackProps> = ({ label, type }) => {
  return (
    <Alert variant="filled" severity={type}>
      {label}
    </Alert>
  );
};

export default CustomFeedback;
