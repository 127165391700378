import React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomList from "../../custom/CustomList";
import CustomText from "../../custom/CustomText";

type CustomButtonProps = {
  open: boolean;
  handleClose: () => void;
  details: { code: string; description: string; quantity: string }[];
};

const AzDetails: React.FC<CustomButtonProps> = ({
  open,
  handleClose,
  details,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        {details.length > 0 ? (
          <CustomList
            listElement={[
              ...details.map((detail: any) => ({
                primaryText: `${detail.code} - Q.tà ${detail.quantity}`,
                secondaryText: detail.description,
              })),
            ]}
          />
        ) : (
          <CustomText type="subtitle1" label={t("noDetailsFound")} />
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          label={t("close")}
          type="outlined"
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AzDetails;
