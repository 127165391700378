import { Grid, Typography } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import { getAzMaintenance } from "../../../api/services/azService";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomButton from "../../custom/CustomButton";
import CustomTable from "../../custom/CustomTable";
import CustomIconButton from "../../custom/CustomIconButton";
import { Visibility } from "@mui/icons-material";
import AzDetails from "./AzDetails";
import emptyImg from "../../../images/empty.svg";
import { set } from "lodash";
import CustomText from "../../custom/CustomText";
import CustomLoading from "../../custom/CustomLoading";
import { getElevators } from "../../../api/services/elevatorService";
import CustomSelect from "../../custom/CustomSelect";
import { useSnackbar } from "notistack";

type AzMaintenanceProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const AzMaintenance: React.FC<AzMaintenanceProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {`${t("maintenanceHistoric")} ${t("ordinaryExtraordinary")}`}
      </Typography>,
    ]);
  }, []);

  const [loadMaintenance, setLoadMaintenance] = useState<boolean>(true);
  const [maintenances, setMaintenances] = useState<any[]>([]);

  const handleSearch = (elevator?: any, allElevators?: any[]) => {
    if (new Date(values.start_date) <= new Date(values.end_date)) {
      setLoadMaintenance(true);
      const tmpEl = elevator ? elevator : values.elevatorId;
      let promises: Promise<any>[] = [];
      tmpEl.forEach((i: any) => {
        if (i) {
          const maintenancePromise = getAzMaintenance(i).then((res: any) => {
            if (res && res.maintenance_history) {
              const tmpInst = (allElevators ? allElevators : elevators).find(
                (el) => el.id_gestionale === i
              );
              return res.maintenance_history.map((m: any) => ({
                ...m,
                installation_container_name:
                  tmpInst?.installation_container_name,
                system_number: tmpInst?.system_number,
                id_gestionale: tmpInst?.id_gestionale,
              }));
            } else {
              return [];
            }
          });
          promises.push(maintenancePromise);
        }
      });
      Promise.all(promises)
        .then((results) => {
          let tmpData = results.flat();
          tmpData = tmpData.filter(
            (m: any) =>
              new Date(m.date).getTime() >=
                new Date(values.start_date).getTime() &&
              new Date(m.date).getTime() <= new Date(values.end_date).getTime()
          );
          tmpData.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          if (tmpData.length > 0) {
            setMaintenances([...tmpData]);
          } else {
            setMaintenances([]);
          }
          setLoadMaintenance(false);
        })
        .catch((error) => {
          console.error("Error fetching maintenance data:", error);
          setMaintenances([]);
          setLoadMaintenance(false);
        });
    } else {
      enqueueSnackbar(t("startEndDate"), { variant: "error" });
    }
  };

  const [open, setOpen] = useState<boolean>(false);
  const [details, setDetails] = useState<any[]>([]);

  function formatDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [values, setValues] = useState<any>({
    elevatorId: [],
    start_date: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    end_date: formatDate(new Date()),
  });

  const [loadingElevators, setLoadingElevators] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  useEffect(() => {
    getElevators().then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
        setValues({
          ...values,
          elevatorId: res.installations.map((el: any) =>
            el.id_gestionale ? el.id_gestionale : el.id
          ),
        });
        handleSearch(
          res.installations.map((el: any) => el.id_gestionale),
          res.installations
        );
      }
      setLoadingElevators(false);
    });
  }, []);

  if (loadingElevators) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("maintenanceHistoric")} ${t("ordinaryExtraordinary")}`}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CustomSelect
                  multiple
                  value={values.elevatorId}
                  label={`${t("selectElevator")}`}
                  handleChange={(e: any) =>
                    setValues({
                      ...values,
                      elevatorId: e.target.value,
                    })
                  }
                  options={elevators.map((el) => ({
                    value: el.id_gestionale ? el.id_gestionale : el.id,
                    label: `${el.name}`,
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label={t("startDate")}
                  type="date"
                  value={values.start_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, start_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label={t("endDate")}
                  type="date"
                  value={values.end_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, end_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButton
                  label={t("reset")}
                  onClick={() => {
                    setValues({
                      elevatorId: [],
                      start_date: formatDate(
                        new Date(new Date().setDate(new Date().getDate() - 30))
                      ),
                      end_date: formatDate(new Date()),
                    });
                  }}
                  type="outlined"
                  confirm
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButton
                  label={t("search")}
                  onClick={() => handleSearch(undefined, undefined)}
                  type="contained"
                  fullWidth
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        {loadMaintenance ? (
          <Grid item xs={12}>
            <CustomLoading />
          </Grid>
        ) : maintenances.length > 0 ? (
          <CustomTable
            columns={[
              {
                headerName: `${t("name")}`,
                field: "installation_container_name",
                flex: 1,
              },
              {
                headerName: `${t("systemNumber")}`,
                field: "system_number",
                flex: 1,
              },
              {
                headerName: `${t("idGestionale")}`,
                field: "id_gestionale",
                flex: 1,
              },
              {
                headerName: `${t("date")}`,
                field: "date",
                flex: 1,
                renderCell: (params: any) => {
                  return new Date(params.row.date).toLocaleDateString();
                },
              },
              { headerName: `${t("id")}`, field: "id", flex: 0.7 },
              {
                headerName: `${t("type")}`,
                field: "type_description",
                flex: 1.2,
              },
              {
                headerName: `${t("description")}`,
                field: "description",
                flex: 1,
              },
              {
                headerName: `${t("details")}`,
                field: "activities",
                flex: 0.5,
                renderCell: (params: any) => {
                  return (
                    <CustomIconButton
                      type="outlined"
                      icon={<Visibility />}
                      onClick={() => {
                        setDetails(params.row.activities);
                        setOpen(true);
                      }}
                    />
                  );
                },
              },
            ]}
            rows={[...maintenances]}
          />
        ) : (
          <Grid item container xs={12}>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginTop: "80px" }}
            >
              <CustomText label={`${t("noData")}`} type="h5" />
            </Grid>
            <Grid item xs={12}>
              <img
                src={emptyImg}
                alt="logo"
                width="20%"
                style={{ marginLeft: "39%", marginRight: "39%" }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <AzDetails
        open={open}
        handleClose={() => setOpen(false)}
        details={details}
      />
    </Grid>
  );
};

export default AzMaintenance;
