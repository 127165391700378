import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import {
  companyListToDownloadDataUrl,
  elevatorListToDownloadDataUrl,
  mainUrl,
} from "../costants";
import { useNavigate, useParams } from "react-router-dom";
import CustomList from "../../custom/CustomList";
import CustomCard from "../../custom/CustomCard";
import CustomTag from "../../custom/CustomTag";
import CustomInput from "../../custom/CustomInput";
import { Link } from "react-router-dom";
import {
  getElevator,
  getExportInfo,
} from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import { GetExportInfoRequest } from "../../../api/requests/elevatorService";

type DataHistoryProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  companyInfo: boolean;
};

const DataHistory: React.FC<DataHistoryProps> = ({
  setBreadcrumb,
  companyInfo,
}) => {
  const { id } = useParams(); // device-id
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading2, setLoading2] = useState<boolean>(true);
  const [elevator, setElevator] = useState({
    company: "",
    name: "",
    status: "",
    systemNumber: "",
    systemSerialNumber: "",
    address: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      ...(companyInfo
        ? [
            <Link
              style={{ color: "#ffffff" }}
              key="2"
              to={companyListToDownloadDataUrl}
            >
              {t("dataHistory")}
            </Link>,
          ]
        : [
            <Link
              style={{ color: "#ffffff" }}
              key="2"
              to={elevatorListToDownloadDataUrl("all")}
            >
              {t("dataHistory")}
            </Link>,
          ]),
      <Typography color="#ffffff" key="4">
        {t("dataDownload")}
      </Typography>,
    ]);
  }, [elevator]);

  useEffect(() => {
    getElevator(id!).then((res) => {
      if (res && res.installation) {
        setElevator({
          company: res.installation?.azienda,
          address:
            res.installation?.installation_info?.address +
            ", " +
            res.installation?.installation_info?.province +
            " " +
            res.installation?.installation_info?.city,
          name: res.installation.name,
          status: res.installation.status,
          systemNumber: res.installation.system_number,
          systemSerialNumber: res.installation.system_serial_number,
        });
      }
      setLoading2(false);
    });
  }, []);

  // Funzione per formattare la data nel formato YYYY-MM-DD
  function formatDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const downloadFile = useCallback((url: string) => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = url;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }, []);

  const [values, setValues] = useState({
    start_date: formatDate(
      new Date(new Date().setDate(new Date().getDate() - 6))
    ),
    end_date: formatDate(new Date()),
  });
  const handleSubmit = useCallback(() => {
    const first = new Date(values.start_date);
    const second = new Date(values.end_date);
    let differenceInTime = second.getTime() - first.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);
    if (differenceInDays <= 6) {
      const info: GetExportInfoRequest = {
        tag: "d",
        start: new Date(values.start_date).toISOString().split("T")[0],
        end: new Date(values.end_date).toISOString().split("T")[0],
        entity_id: id!,
      };
      getExportInfo(info)
        .then((resp) => {
          if (resp && resp.url) {
            downloadFile(resp.url);
          } else if (resp && resp.err) {
            enqueueSnackbar(resp?.err?.message || t("downloadErrror"), {
              variant: "error",
              preventDuplicate: true,
            });
          }
        })
        .catch((e) => console.log("error: ", e));
    } else {
      enqueueSnackbar(t("sevenDaysError"), {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [values]);

  if (loading2) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t("dataDownload")} goBack={() => navigate(-1)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          header={{
            visible: true,
            title: `${t("elevatorRegistry")}`,
          }}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomList
                  listElement={[
                    {
                      primaryText: "Zerynth device",
                      action: (
                        <CustomTag
                          label={String(elevator.status).toLocaleUpperCase()}
                          type="filled"
                          color={
                            String(elevator.status).toLocaleLowerCase() ===
                            "online"
                              ? "green"
                              : "red"
                          }
                        />
                      ),
                    },
                    {
                      primaryText: t("systemNumber"),
                      action: elevator.systemNumber,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomList
                  listElement={[
                    {
                      primaryText: t("systemSerialNumber"),
                      action: elevator.systemSerialNumber,
                    },
                    {
                      primaryText: t("address"),
                      action: elevator.address,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("startDate")}
                  type="date"
                  value={values.start_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, start_date: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("endDate")}
                  type="date"
                  value={values.end_date}
                  handleChange={(e: any) => {
                    setValues({ ...values, end_date: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              type="outlined"
              label={t("reset")}
              onClick={() => {
                setValues({ start_date: "", end_date: "" });
              }}
              confirm
              confirmQuestion={`${t("question")}`}
            />,
            <CustomButton
              fullWidth
              type="contained"
              label={t("downloadCsv")}
              onClick={handleSubmit}
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default DataHistory;
