import {
  LoginRequest,
  ResetPasswordRequest,
  SetNewPasswordRequest,
} from "../requests/loginService";
import { methodPost, verticalFetch } from "./httpRequests";
import {
  loginUrl,
  logoutUrl,
  resetPasswordUrl,
  setNewPasswordUrl,
} from "./url";

export const login = (loginRequest: LoginRequest) => {
  return verticalFetch(methodPost, loginUrl, JSON.stringify(loginRequest));
};

export const resetPassword = (resetPasswordRequest: ResetPasswordRequest) => {
  return verticalFetch(
    methodPost,
    resetPasswordUrl,
    JSON.stringify(resetPasswordRequest)
  );
};

export const logout = () => {
  return verticalFetch(methodPost, logoutUrl);
};

export const recoverPassword = (req: SetNewPasswordRequest) => {
  return verticalFetch(methodPost, setNewPasswordUrl, JSON.stringify(req));
};
