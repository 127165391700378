/**
 * @name slipEncode
 * Take an array buffer and return back a new array where
 * 0xdb is replaced with 0xdb 0xdd and 0xc0 is replaced with 0xdb 0xdc
 */
export const slipEncode = (buffer: number[]): number[] => {
  let encoded = [0xc0];
  for (let byte of buffer) {
    if (byte === 0xdb) {
      encoded = encoded.concat([0xdb, 0xdd]);
    } else if (byte === 0xc0) {
      encoded = encoded.concat([0xdb, 0xdc]);
    } else {
      encoded.push(byte);
    }
  }
  encoded.push(0xc0);
  return encoded;
};

/**
 * @name toByteArray
 * Convert a string to a byte array
 */
export const toByteArray = (str: string): number[] => {
  let byteArray: number[] = [];
  for (let i = 0; i < str.length; i++) {
    let charcode = str.charCodeAt(i);
    if (charcode <= 0xff) {
      byteArray.push(charcode);
    }
  }
  return byteArray;
};

export const hexFormatter = (bytes: number[]) =>
  "[" + bytes.map((value) => toHex(value)).join(", ") + "]";

export const toHex = (value: number, size = 2) => {
  let hex = value.toString(16).toUpperCase();
  if (hex.startsWith("-")) {
    return "-0x" + hex.substring(1).padStart(size, "0");
  } else {
    return "0x" + hex.padStart(size, "0");
  }
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
