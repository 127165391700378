import {
  adminMenuItems,
  companyMenuItems,
  customerMenuItems,
  installerMenuItems,
  mantainerMenuItems,
  respMantainerMenuItems,
  superadminMenuItems,
} from "./menuItems";
import React, { useEffect, useState } from "react";
import { getProfile } from "../../api/services/userService";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@mui/icons-material";
import { capitalizedString, darkColor, lightColor } from "../custom/utils";
import { roles } from "../pages/costants";

type NavbarProps = {
  SetNavbarIsCollapsed: (collapsed: boolean) => void;
  navbar_collapsed: boolean;
  isMobile: boolean;
  configuratorRunning: boolean;
};

const Navbar: React.FC<NavbarProps> = ({
  SetNavbarIsCollapsed,
  navbar_collapsed,
  isMobile,
  configuratorRunning,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState<string>("");
  const [panelOpen, setPanelOpen] = useState<boolean[]>([false]);
  const [menuItem, setMenuItem] = useState<any[]>([]);

  useEffect(() => {
    getProfile().then((res: any) => {
      if (res && res.user && res.user.roles && res.user.roles.length > 0) {
        if (res.user.roles[0] === roles.superadmin) {
          setMenuItem(superadminMenuItems(t));
        }
        if (res.user.roles[0] === roles.admin) {
          setMenuItem(adminMenuItems(t));
        }
        if (res.user.roles[0] === roles.installer) {
          setMenuItem(installerMenuItems(t));
        }
        if (res.user.roles[0] === roles.customer) {
          setMenuItem(customerMenuItems(t));
        }
        if (res.user.roles[0] === roles.company) {
          setMenuItem(companyMenuItems(t));
        }
        if (res.user.roles[0] === roles.maintainer) {
          setMenuItem(mantainerMenuItems(t));
        }
        if (res.user.roles[0] === roles.respMaintainer) {
          setMenuItem(respMantainerMenuItems(t));
        }
      }
    });
  }, [i18n.language]);

  return (
    <Drawer
      onClose={() => SetNavbarIsCollapsed(true)}
      open={isMobile ? !navbar_collapsed : true}
      variant={!isMobile ? "persistent" : undefined}
    >
      <List
        style={{
          backgroundColor: lightColor,
          color: darkColor,
          paddingTop: "66px",
          width: "230px",
          height: "100%",
        }}
      >
        {menuItem.map((item: any, index: number) => {
          return (
            <div>
              <ListItem
                button
                key={item.key}
                onClick={() => {
                  if (item?.link) {
                    if (typeof item.link === "string") {
                      navigate(item.link);
                    } else {
                      navigate(item.link());
                    }
                  }
                  if (item.elements.length > 0) {
                    let tmp = panelOpen;
                    tmp[index] = !tmp[index];
                    tmp = tmp.map((e, i) => (i === index ? e : false));
                    setPanelOpen([...tmp]);
                  } else {
                    setSelectedItem(item.key);
                    SetNavbarIsCollapsed(true);
                  }
                }}
                selected={selectedItem === item.key}
                disabled={configuratorRunning}
              >
                <ListItemText
                  sx={{ fontSize: "14px" }}
                  primary={capitalizedString(item.label)}
                />
                {item.elements.length > 0 && <ExpandMore htmlColor="#455a64" />}
              </ListItem>
              {item.elements.length > 0 && (
                <Collapse in={panelOpen[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.elements.map((e: any) => (
                      <ListItem
                        disabled={configuratorRunning}
                        key={e.key}
                        selected={selectedItem === e.key}
                        button
                        onClick={() => {
                          if (e?.link) {
                            setSelectedItem(e.key);
                            if (typeof e.link === "string") {
                              navigate(e.link);
                            } else {
                              navigate(e.link());
                            }
                            SetNavbarIsCollapsed(true);
                          }
                        }}
                      >
                        <ListItemText primary={capitalizedString(e.label)} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
              <Divider />
            </div>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Navbar;
