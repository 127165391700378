import { Grid, Typography } from "@mui/material";
import CustomTitle from "../../custom/CustomTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl, roles } from "../costants";
import CustomSelect from "../../custom/CustomSelect";
import CustomTable from "../../custom/CustomTable";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete } from "@mui/icons-material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import {
  assignCustomer,
  assignMantainer,
  assignResponsible,
  changeInstaller,
  unassignCustomer,
  unassignMantainer,
  unassignResponsible,
} from "../../../api/services/allocatorService";
import { getUsers } from "../../../api/services/userService";
import { useSnackbar } from "notistack";
import { getElevators } from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import CustomTag from "../../custom/CustomTag";
import { greyColor } from "../../custom/utils";

type AssignElevatorProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  types: { label: string; value: string }[];
};

const AssignElevator: React.FC<AssignElevatorProps> = ({
  setBreadcrumb,
  types,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("assignElevators")}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    type: types.length === 1 ? types[0].value : "",
    user: "",
    elevator: "",
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);
  const [reload, setReload] = useState<boolean>(true);
  const [associations, setAssociations] = useState<any[]>([]);
  useEffect(() => {
    getUsers("installers").then((resInstallers) => {
      getUsers("manutentori").then((resMaintainers) => {
        getUsers("responsabili").then((resResponsibles) => {
          getUsers("customers").then((resCustomers) => {
            getElevators().then((res: any) => {
              if (res && res.installations) {
                setElevators([...res.installations]);
                const tmp: any[] = [];
                res.installations.forEach((e: any) => {
                  if (
                    e.actual_installer &&
                    types.find((t) => t.value === roles.installer)
                  ) {
                    tmp.push({
                      userId: e.actual_installer,
                      userName: e.actual_installer_name,
                      email:
                        (resInstallers?.installers || []).find(
                          (i: any) => i.uid === e?.actual_installer
                        )?.email || "N/A",
                      role: roles.installer,
                      elevatorId: e.id,
                      elevatorName: e.installation_container_name,
                    });
                  }
                  if (
                    e.manutentore_assigned &&
                    types.find((t) => t.value === roles.maintainer)
                  ) {
                    tmp.push({
                      userId: e.manutentore_assigned,
                      userName: e.manutentore_assigned_name,
                      email:
                        (resMaintainers?.manutentori || []).find(
                          (m: any) => m.uid === e?.manutentore_assigned
                        )?.email || "N/A",
                      role: roles.maintainer,
                      elevatorId: e.id,
                      elevatorName: e.installation_container_name,
                    });
                  }
                  if (
                    e.customer_assigned &&
                    e.customer_assigned.length > 0 &&
                    types.find((t) => t.value === roles.customer)
                  ) {
                    e.customer_assigned.forEach((c: any) => {
                      tmp.push({
                        userId: c,
                        userName: (resCustomers?.customers || []).find(
                          (cu: any) => cu.uid === c
                        )?.name,
                        email:
                          (resCustomers?.customers || []).find(
                            (cu: any) => cu.uid === c
                          )?.email || "N/A",
                        role: roles.customer,
                        elevatorId: e.id,
                        elevatorName: e.installation_container_name,
                      });
                    });
                  }
                  if (
                    e.responsabile_assigned &&
                    e.responsabile_assigned.length > 0 &&
                    types.find((t) => t.value === roles.respMaintainer)
                  ) {
                    e.responsabile_assigned.forEach((c: any) => {
                      tmp.push({
                        userId: c,
                        userName: (resResponsibles?.responsabili || []).find(
                          (cu: any) => cu.uid === c
                        )?.name,
                        email:
                          (resResponsibles?.responsabili || []).find(
                            (cu: any) => cu.uid === c
                          )?.email || "N/A",
                        role: roles.respMaintainer,
                        elevatorId: e.id,
                        elevatorName: e.installation_container_name,
                      });
                    });
                  }
                });
                setAssociations(tmp);
              }
              setLoading(false);
            });
          });
        });
      });
    });
  }, [reload]);

  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (values.type === roles.installer) {
      getUsers("installers").then((res) => {
        if (res && res.installers) {
          setUsers(res.installers);
        } else {
          setUsers([]);
        }
      });
    }
    if (values.type === roles.maintainer) {
      getUsers("manutentori").then((res) => {
        if (res && res.manutentori) {
          setUsers(res.manutentori);
        } else {
          setUsers([]);
        }
      });
    }
    if (values.type === roles.customer) {
      getUsers("customers").then((res) => {
        if (res && res.customers) {
          setUsers(res.customers);
        } else {
          setUsers([]);
        }
      });
    }
    if (values.type === roles.respMaintainer) {
      getUsers("responsabili").then((res) => {
        if (res && res.responsabili) {
          setUsers(res.responsabili);
        } else {
          setUsers([]);
        }
      });
    }
  }, [values.type]);

  const handleSubmit = () => {
    if (values.type === roles.installer) {
      changeInstaller(values.elevator, values.user).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("assignInstallerSuccess"), {
            variant: "success",
          });
          setValues({
            type: types.length === 1 ? types[0].value : "",
            user: "",
            elevator: "",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(`${t("assignError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
      });
    }
    if (values.type === roles.maintainer) {
      assignMantainer(values.elevator, values.user).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("assignMantainerSuccess"), {
            variant: "success",
          });
          setValues({
            type: types.length === 1 ? types[0].value : "",
            user: "",
            elevator: "",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(`${t("assignError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
      });
    }
    if (values.type === roles.customer) {
      assignCustomer(values.elevator, values.user).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("assignUserSuccess"), {
            variant: "success",
          });
          setValues({
            type: types.length === 1 ? types[0].value : "",
            user: "",
            elevator: "",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(`${t("assignError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
      });
    }
    if (values.type === roles.respMaintainer) {
      assignResponsible(values.elevator, values.user).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("assignUserSuccess"), {
            variant: "success",
          });
          setValues({
            type: types.length === 1 ? types[0].value : "",
            user: "",
            elevator: "",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(`${t("assignError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
      });
    }
  };

  const handleDelete = (installationId: string, userId: any, role: string) => {
    if (role === roles.installer) {
      changeInstaller(installationId, userId).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("dissociateUserToCustomerSuccess"), {
            variant: "success",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(
            `${t("dissociateUserToCustomerError")} ${res?.err?.message}`,
            {
              variant: "error",
            }
          );
        }
      });
    }
    if (role === roles.maintainer) {
      unassignMantainer(installationId, userId).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("dissociateUserToCustomerSuccess"), {
            variant: "success",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(
            `${t("dissociateUserToCustomerError")} ${res?.err?.message}`,
            {
              variant: "error",
            }
          );
        }
      });
    }
    if (role === roles.customer) {
      unassignCustomer(installationId, userId).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("dissociateUserToCustomerSuccess"), {
            variant: "success",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(
            `${t("dissociateUserToCustomerError")} ${res?.err?.message}`,
            {
              variant: "error",
            }
          );
        }
      });
    }
    if (role === roles.respMaintainer) {
      unassignResponsible(installationId, userId).then((res) => {
        if (res && !res.err) {
          enqueueSnackbar(t("dissociateUserToCustomerSuccess"), {
            variant: "success",
          });
          setReload(!reload);
        } else {
          enqueueSnackbar(
            `${t("dissociateUserToCustomerError")} ${res?.err?.message}`,
            {
              variant: "error",
            }
          );
        }
      });
    }
  };

  const checkRole = (role: string) => {
    switch (role) {
      case roles.installer:
        return t("installer");
      case roles.maintainer:
        return t("mantainer");
      case roles.customer:
        return t("customer");
      case roles.respMaintainer:
        return t("mantainerResponsible");
      default:
        return role;
    }
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("assignLifts")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item container xs={12} spacing={2}>
                {types.length > 1 && (
                  <Grid item xs={12} md>
                    <CustomSelect
                      label={`${t("role")}`}
                      value={values.type}
                      handleChange={(e) =>
                        setValues((prev) => ({ ...prev, type: e.target.value }))
                      }
                      options={types}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md>
                  <CustomSelect
                    label={`${t("user")}`}
                    value={values.user}
                    handleChange={(e) =>
                      setValues((prev) => ({ ...prev, user: e.target.value }))
                    }
                    options={users.map((u: any) => ({
                      label: u.email,
                      value: u.uid,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} md>
                  <CustomSelect
                    label={`${t("lift")}`}
                    value={values.elevator}
                    handleChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        elevator: e.target.value,
                      }))
                    }
                    options={
                      elevators.map((e: any) => ({
                        label: `${e.installation_container_name} - ${e?.system_number} (${e.id})`,
                        value: e.id,
                      })) || []
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              confirm
              fullWidth
              type="outlined"
              label={t("reset")}
              onClick={() => setValues({ type: "", user: "", elevator: "" })}
            />,
            <CustomButton
              fullWidth
              type="contained"
              label={t("ok")}
              onClick={handleSubmit}
            />,
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          columns={[
            { headerName: `${t("user")}`, field: "userName", flex: 1 },
            { headerName: `${t("email")}`, field: "email", flex: 1 },
            {
              headerName: `${t("role")}`,
              field: "role",
              flex: 1,
              renderCell: (params: GridRenderCellParams) => (
                <CustomTag
                  label={checkRole(params.row.role)}
                  color={greyColor}
                  type="filled"
                />
              ),
            },
            { headerName: `${t("lift")}`, field: "elevatorName", flex: 1 },
            {
              headerName: `${t("delete")}`,
              field: "birthday",
              flex: 0.5,
              renderCell: (params: GridRenderCellParams) => {
                if (params.row.role !== roles.installer) {
                  return (
                    <CustomIconButton
                      confirm
                      tooltip={`${t("delete")}`}
                      type="outlined"
                      icon={<Delete />}
                      onClick={() => {
                        handleDelete(
                          params.row.elevatorId,
                          params.row.userId,
                          params.row.role
                        );
                      }}
                    />
                  );
                }
              },
            },
          ]}
          rows={[...associations]}
        />
      </Grid>
    </Grid>
  );
};

export default AssignElevator;
