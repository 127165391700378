import { isDev } from "../../components/pages/costants";
import { GetExportInfoRequest } from "../requests/elevatorService";

// BASE URLS
export const backendUrl = window._env_.API_ENDPOINT!;
export const grafanaUrl = window._env_.GRAFANA_ENDPOINT!;
export const zdmUrl = isDev
  ? "https://baglini.app.localhost/proxy"
  : `https://app.baglini.zerynth.com/proxy`;
export const azBaseUrl = "https://api.future.baglinigroup.it";

//LOGIN SERVICE
export const loginUrl = `${backendUrl}/auth/login`;
export const logoutUrl = `${backendUrl}/auth/logout`;
export const setNewPasswordUrl = `${backendUrl}/auth/recover/end`;
export const resetPasswordUrl = `${backendUrl}/auth/recover`;

//USERS SERVICE
export const getUsersUrl = (
  type:
    | "customers"
    | "manutentori"
    | "responsabili"
    | "installers"
    | "aziende"
    | "admins"
) => `${backendUrl}/users/${type}`;
export const createUserUrl = `${backendUrl}/users`;
export const getProfileUrl = `${backendUrl}/auth/profile`;
export const getUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;
export const updateUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;
export const getCompanyByAdminUrl = (uid: string) =>
  `${backendUrl}/users/aziende?admin=${uid}`;
export const getCompaniesUrl = () => `${backendUrl}/users/aziende`;
export const deleteUserUrl = (uid: string) => `${backendUrl}/users/${uid}`;

// CONFGURATOR SERVICE
export const createNewDeviceUrl = (workspaceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices`;
export const getIdentityFromDCNUrl = (dcn: string) =>
  `${zdmUrl}/identities/${dcn}/devices`;
export const claimDeviceUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/identities`;
export const activeSimUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/sims`;
export const mklfsUrl = `${zdmUrl}/mklfs/`; // Non rimuovere lo / finale perché senno smette di funzionare
export const initUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/init`;
export const uploadFilesUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/upload`;
export const commitUrl = (
  workspaceId: string,
  deviceId: string,
  schedule: boolean
) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/commit?auto_schedule=${
    schedule ? "true" : "false"
  }`;
export const getFirmwareVersionsUrl = (
  workspaceId: string,
  firmwareId: string
) => `${zdmUrl}/workspaces/${workspaceId}/firmwares/${firmwareId}/versions`;
export const getDeviceUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}`;
export const getZfsUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/files?zone=R`;
export const getZfsFileUrl = (
  workspaceId: string,
  deviceId: string,
  zfsId: string
) =>
  `${zdmUrl}/workspaces/${workspaceId}/devices/${deviceId}/zfs/download/${zfsId}`;
export const getJobsUrl = (deviceId: string) =>
  `${zdmUrl}/devices/${deviceId}/jobs`;
export const checkZfsStatusUrl = (deviceId: string) =>
  `${zdmUrl}/devices/${deviceId}/jobs/zfs`;
export const getJobStatusUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/timeseries/${workspaceId}/events?type=job&exclude=data&size=150&from=0&device=${deviceId}`;
export const getLastDUrl = (workspaceId: string, deviceId: string) =>
  `${zdmUrl}/timeseries/${workspaceId}/data?size=1&from=0&device=${deviceId}&tag=b`;

// ALLOCATOR SERVICE
export const assignResponsibleUrl = (
  installationId: string,
  mantainerId: string
) =>
  `${backendUrl}/installations/${installationId}/assignToResponsabile/${mantainerId}`;
export const unassignResponsibleUrl = (
  installationId: string,
  mantainerId: string
) =>
  `${backendUrl}/installations/${installationId}/unassignToResponsabile/${mantainerId}`;
export const assignMantainerUrl = (
  installationId: string,
  mantainerId: string
) =>
  `${backendUrl}/installations/${installationId}/assignToManutentore/${mantainerId}`;
export const unassignMantainerUrl = (
  installationId: string,
  mantainerId: string
) =>
  `${backendUrl}/installations/${installationId}/unassignToManutentore/${mantainerId}`;
export const assignCustomerUrl = (installationId: string, customerId: string) =>
  `${backendUrl}/installations/${installationId}/assignToCustomer/${customerId}`;
export const unassignCustomerUrl = (
  installationId: string,
  customerId: string
) =>
  `${backendUrl}/installations/${installationId}/unassignToCustomer/${customerId}`;
export const changeInstallerUrl = (
  installationId: string,
  installerId: string
) =>
  `${backendUrl}/installations/${installationId}/changeInstaller/${installerId}`;
export const assignBoardTypeUrl = (adminId: string, boardType: string) =>
  `${backendUrl}/users/assignBoard/${adminId}/${boardType}`;

// AZ SERVICE
export const getAzElevatorUrl = (id: string) =>
  `${azBaseUrl}/api/v1/installations/${id}`;
export const idAssociationUrl = `${azBaseUrl}/api/v1/installations/set-device-id`;
export const updateAzElevatorUrl = (id: string) =>
  `${azBaseUrl}/api/v1/installations/${id}`;
export const getAzMaintenanceUrl = (id: string) =>
  `${azBaseUrl}/api/v1/maintenance-history?sys_num=${id}`;

// ELEVATOR SERVICE
export const createElevatorUrl = `${backendUrl}/installations`;
export const getElevatorsUrl = `${backendUrl}/installations`;
export const getCompanyElevatorsUrl = (id: string) =>
  `${backendUrl}/installations/azienda/${id}`;
export const getElevatorUrl = (id: string) =>
  `${backendUrl}/installations/${id}`;
export const deleteElevatorUrl = `${backendUrl}/installations`;
export const getBoardTypeUrl = `${backendUrl}/users/getBoardType`;
export const getContainersUrl = `${backendUrl}/installations/installationsContainers`;
export const sendJobUrl = (id: string, jobName: string) =>
  `${zdmUrl}/devices/${id}/jobs/${jobName}`;
export const getAlarmsUrl = (start: string, end: string) =>
  `${backendUrl}/timeseries/alarms/installations?start=${start}&end=${end}&sort=-start`;
export const getElevatorAlarmsUrl = (id: string, end: boolean) => {
  let tmp = `${backendUrl}/timeseries/alarms/installations?installation_id=${id}&sort=-start`;
  if (end) {
    tmp = tmp + "&end=noend";
  }
  return tmp;
};
export const updateAlarmsCalibrationUrl = (id: string) =>
  `${backendUrl}/installations/${id}/alarms`;
export const getAlarmsCalibrationUrl = (id: string) =>
  `${backendUrl}/installations/${id}/alarms`;
export const getExportInfoUrl = (req: GetExportInfoRequest) => {
  const baseUrl = new URL(`${backendUrl}/timeseries/data/exports`);
  baseUrl.searchParams.append("start", req.start);
  baseUrl.searchParams.append("end", req.end);
  baseUrl.searchParams.append("entity_id", req.entity_id);
  baseUrl.searchParams.append("tag", req.tag);
  return baseUrl.toString();
};
export const getLastDataUrl = (id: string, tag: string, variable: string) => {
    if (variable !== "") {
        return `${backendUrl}/timeseries/data/${id}/${tag}.${variable}?sort=DESC&last=true`;
    }
    return `${backendUrl}/timeseries/data/${id}/${tag}?sort=DESC&last=true`;
}
export const updateElevatorUrl = (id: string) =>
  `${backendUrl}/installations/${id}`;
