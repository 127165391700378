import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { alarmsThresholdUrl, mainUrl, roles } from "../costants";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip, Typography } from "@mui/material";
import CustomTable from "../../custom/CustomTable";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import CustomIconButton from "../../custom/CustomIconButton";
import { Delete, Edit, EditNotifications } from "@mui/icons-material";
import {
  deleteElevator,
  getElevators,
} from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import { formatAddress } from "../../custom/utils";

type ElevatorsListProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  link: string | ((id: string) => string);
  role: string;
};

const ElevatorsList: React.FC<ElevatorsListProps> = ({
  setBreadcrumb,
  link,
  role,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  document.body.style.backgroundColor = "#ffffff";

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {role === roles.company ? t("updateDeleteDevice") : t("updateDevice")}
      </Typography>,
    ]);
  }, []);

  const [reload, setReload] = useState<boolean>(false);
  useEffect(() => {
    getElevators().then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoading(false);
    });
  }, [reload]);

  const [columns, setColumns] = React.useState<GridColDef[]>([
    {
      headerName: `${t("name")}`,
      field: "name",
      flex: 1,
    },
    {
      headerName: `${t("systemNumber")}`,
      field: "system_number",
      flex: 1,
    },
    {
      headerName: `${t("systemSerialNumber")}`,
      field: "system_serial_number",
      flex: 1,
    },
    {
      headerName: `${t("idGestionale")}`,
      field: "idGestionale",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>{params.row.id_gestionale ? params.row.id_gestionale : "N/A"}</>
      ),
    },
    {
      headerName: `${t("address")}`,
      field: "installation_info",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>{formatAddress(params.row.installation_info)}</>
      ),
    },
    {
      headerName: `${t("installationDate")}`,
      field: "created_at",
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        new Date(params.row.created_at).toLocaleString(),
    },
    {
      headerName: ``,
      field: "id",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <CustomIconButton
            icon={<Edit />}
            type="outlined"
            onClick={() =>
              typeof link === "string"
                ? navigate(link)
                : navigate(link(params.row.id))
            }
            tooltip={`${t("edit")}`}
          />
          {role === roles.respMaintainer ||
            (role === roles.company && (
              <CustomIconButton
                type="outlined"
                icon={<Delete />}
                confirm
                tooltip={`${t("delete")}`}
                onClick={() =>
                  deleteElevator(params.row.id).then((res) => {
                    if (res && !res.err) {
                      setReload((prev) => !prev);
                      enqueueSnackbar(t("elevatorDeletedSuccess"), {
                        variant: "success",
                      });
                    } else {
                      enqueueSnackbar(
                        t("elevatorDeletedError") + " " + res?.err?.message,
                        {
                          variant: "error",
                        }
                      );
                    }
                  })
                }
              />
            ))}
          {role === roles.respMaintainer && (
            <CustomIconButton
              type="outlined"
              icon={<EditNotifications />}
              onClick={() => navigate(alarmsThresholdUrl(params.row.id))}
              tooltip={`Modifica soglie allarme`}
            />
          )}
        </>
      ),
    },
  ]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={
            role === roles.company ? t("updateDeleteDevice") : t("updateDevice")
          }
          goBack={() => navigate("/" + mainUrl)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTable columns={columns} rows={[...elevators]} />
      </Grid>
    </Grid>
  );
};

export default ElevatorsList;
