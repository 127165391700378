import React, { useState } from "react";
import { login } from "../../../api/services/loginService";
import { LoginRequest } from "../../../api/requests/loginService";
import logo from "../../../images/logo_verticale.svg";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomCard from "../../custom/CustomCard";
import { darkColor } from "../../custom/utils";
import CustomText from "../../custom/CustomText";
import CustomInput from "../../custom/CustomInput";
import CustomIconButton from "../../custom/CustomIconButton";
import { useNavigate } from "react-router-dom";
import { mainUrl, resetPasswordPageUrl } from "../costants";

const LoginPanel: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  document.body.style.backgroundColor = darkColor;

  const checkLogin = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    login({ email: email, password: password } as LoginRequest)
      .then((res: any) => {
        if (res && res.status && res.status === "success") {
          navigate(mainUrl);
          localStorage.setItem("isBagliniLogin", "true");
        } else {
          enqueueSnackbar(res?.error?.message || t("incorrectCredentials"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar(t("incorrectCredentials"), {
          variant: "error",
          preventDuplicate: true,
        });
        console.log(e);
      });
  };

  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <img
            src={logo}
            alt="logo"
            width="30%"
            style={{ marginLeft: "34%", marginRight: "34%" }}
          />
        </Grid>
        <Grid item xs={11} md={8}>
          <CustomCard
            content={
              <Grid item container flexDirection="column" spacing={2}>
                <Grid item>
                  <CustomText label={t("welcomeBack")} type="h3" />
                </Grid>
                <Grid item>
                  <CustomInput
                    label={t("email")}
                    value={email}
                    handleChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <CustomInput
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    handleChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <CustomIconButton
                        type="outlined"
                        icon={showPassword ? <VisibilityOff /> : <Visibility />}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md>
                    <CustomButton
                      fullWidth
                      type="outlined"
                      label={t("resetPassword")}
                      onClick={() => navigate(resetPasswordPageUrl)}
                    />
                  </Grid>
                  <Grid item xs={12} md>
                    <CustomButton
                      fullWidth
                      type="contained"
                      label={t("login")}
                      onClick={checkLogin}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginPanel;
