import { isDev } from "../../components/pages/costants";
import { azFetch, methodGet, methodPost, methodPut } from "./httpRequests";
import {
  getAzElevatorUrl,
  getAzMaintenanceUrl,
  idAssociationUrl,
  updateAzElevatorUrl,
} from "./url";

export const getAzElevator = (id: string) => {
  if (isDev) {
    return new Promise((resolve) => {
      resolve({
        name: "Nome_Installazione",
        sys_num: "numero_impianto",
        sys_serial_num: "numero_matricola",
        installation_info: {
          address: "Via xxx",
          city: "xxxxx",
          province: "xx",
        },
      });
    });
  }
  return azFetch(methodGet, getAzElevatorUrl(id));
};

export const idAssociation = (zerynthId: string, azId: string) => {
  if (isDev) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  return azFetch(
    methodPost,
    idAssociationUrl,
    JSON.stringify({
      id_gestionale: azId,
      device_id: zerynthId,
    })
  );
};

export const updateAzElevator = (
  id: string,
  body: {
    sys_serial_num: string;
    installation_info: {
      address: string;
      city: string;
      province: string;
    };
  }
) => {
  if (isDev) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  return azFetch(methodPut, updateAzElevatorUrl(id), JSON.stringify(body));
};

export const getAzMaintenance = (id: string) => {
  return azFetch(methodGet, getAzMaintenanceUrl(id));
};
