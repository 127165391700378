import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import CustomSelect from "../../custom/CustomSelect";
import { createUser as createUserApi } from "../../../api/services/userService";
import { useSnackbar } from "notistack";
import { AddUserRequest } from "../../../api/requests/userService";

type CreateUserProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  roles: { label: string; value: string }[];
};

const CreateUser: React.FC<CreateUserProps> = ({
  setBreadcrumb,
  title,
  roles,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    role: roles.length === 1 ? roles[0].value : "",
    email: "",
    name: "",
    password: "",
    confirm_password: "",
    address: "",
    city: "",
    province: "",
    postal_code: "",
    phone: "",
  });

  const handleSubmit = () => {
    if (
      values.name &&
      values.email &&
      values.password &&
      values.confirm_password
    ) {
      createUserApi({
        name: values.name,
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
        role: values.role,
        user_info: {
          address: values.address,
          city: values.city,
          province: values.province,
          zip_code: values.postal_code,
          phone: values.phone,
        },
      } as AddUserRequest).then((res) => {
        if (res && res.user) {
          setValues({
            role: "",
            email: "",
            name: "",
            password: "",
            confirm_password: "",
            address: "",
            city: "",
            province: "",
            postal_code: "",
            phone: "",
          });
          enqueueSnackbar(t("userCreatedSuccess"), { variant: "success" });
          navigate(mainUrl);
        } else {
          enqueueSnackbar(`${t("userCreatedError")} ${res?.err?.message}`, {
            variant: "error",
          });
        }
      });
    } else {
      enqueueSnackbar(`${t("fillAllUserFields")}`, {
        variant: "error",
      });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              {roles.length > 1 && (
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    options={roles}
                    label={`${t("role")}`}
                    value={values.role}
                    handleChange={(e) =>
                      setValues({ ...values, role: e.target.value })
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("name")}
                  value={values.name}
                  handleChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("email")}
                  value={values.email}
                  handleChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="password"
                  label={t("password")}
                  value={values.password}
                  handleChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  validator={{
                    error:
                      values.password.length >= 8 &&
                      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])/.test(
                        values.password
                      ),
                    helperText: `${t("passwordContentError")}`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="password"
                  label={t("confirmPassword")}
                  value={values.confirm_password}
                  handleChange={(e) =>
                    setValues({ ...values, confirm_password: e.target.value })
                  }
                  validator={{
                    error: values.password !== values.confirm_password,
                    helperText: `${t("passwordDoNotMatch")}`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("onlyAddress")}
                  value={values.address}
                  handleChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("city")}
                  value={values.city}
                  handleChange={(e) =>
                    setValues({ ...values, city: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("province")}
                  value={values.province}
                  handleChange={(e) =>
                    setValues({ ...values, province: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="number"
                  label={t("vat")}
                  value={values.postal_code}
                  handleChange={(e) =>
                    setValues({ ...values, postal_code: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  type="number"
                  label={t("phone")}
                  value={values.phone}
                  handleChange={(e) =>
                    setValues({ ...values, phone: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  role: "",
                  email: "",
                  name: "",
                  password: "",
                  confirm_password: "",
                  address: "",
                  city: "",
                  province: "",
                  postal_code: "",
                  phone: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};
export default CreateUser;
