import React from "react";
import { ListElement } from "./types";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

type CustomListProps = {
  listElement: ListElement[];
  dense?: boolean;
};

const CustomList: React.FC<CustomListProps> = ({ listElement, dense }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <List dense={dense ? dense : false}>
      {listElement.map((element, index) => (
        <ListItem
          alignItems="center"
          onClick={element.onClick ? element.onClick : undefined}
          secondaryAction={
            element.action && !isMobile ? element.action : undefined
          }
        >
          {element.startIcon && (
            <ListItemAvatar>{element.startIcon}</ListItemAvatar>
          )}
          <ListItemText
            primary={element.primaryText}
            secondary={
              element.secondaryText
                ? element.secondaryText
                : element.action && isMobile
                ? element.action
                : undefined
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CustomList;
