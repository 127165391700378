import { CreateNewDeviceRequest } from "../requests/configuratorService";
import {
  ZDMFetch,
  apiKey,
  binaryFetch,
  firmwareId,
  methodGet,
  methodPost,
  methodPut,
  workspaceId,
} from "./httpRequests";
import {
  activeSimUrl,
  checkZfsStatusUrl,
  claimDeviceUrl,
  commitUrl,
  createNewDeviceUrl,
  getDeviceUrl,
  getFirmwareVersionsUrl,
  getIdentityFromDCNUrl,
  getJobStatusUrl,
  getJobsUrl,
  getLastDUrl,
  getZfsFileUrl,
  getZfsUrl,
  initUrl,
  mklfsUrl,
  uploadFilesUrl,
} from "./url";

export const createNewDevice = (req: CreateNewDeviceRequest) => {
  return ZDMFetch(
    methodPost,
    createNewDeviceUrl(workspaceId),
    JSON.stringify(req)
  );
};

export const getIdentityFromDCN = (dcn: string) => {
  return ZDMFetch(methodGet, getIdentityFromDCNUrl(dcn));
};

export const claimDevice = (deviceId: string, bundle: string) => {
  return ZDMFetch(
    methodPost,
    claimDeviceUrl(workspaceId, deviceId),
    JSON.stringify({ bundle: bundle })
  );
};

export const ActiveSim = (deviceId: string) => {
  return ZDMFetch(
    methodPut,
    activeSimUrl(workspaceId, deviceId),
    JSON.stringify({ status: "active" })
  );
};

export const mklfs = (files: { files: { [path: string]: string } }) => {
  return binaryFetch(methodPost, mklfsUrl, JSON.stringify(files));
};

export const init = (deviceId: string) => {
  return ZDMFetch(methodPost, initUrl(workspaceId, deviceId));
};

export const uploadFiles = (deviceId: string, files: { file: File }[]) => {
  const formdata = new FormData();
  files.map((f) => formdata.append(f.file.name, f.file));
  return fetch(uploadFilesUrl(workspaceId, deviceId), {
    method: methodPost,
    body: formdata,
    headers: {
      Accept: "application/json",
      "X-API-KEY": apiKey,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const commit = (deviceId: string, schedule: boolean) => {
  return ZDMFetch(methodPost, commitUrl(workspaceId, deviceId, schedule));
};

export const getFirmwareVersions = () => {
  return ZDMFetch(methodGet, getFirmwareVersionsUrl(workspaceId, firmwareId));
};

export const getDevice = (deviceId: string) => {
  return ZDMFetch(methodGet, getDeviceUrl(workspaceId, deviceId));
};

export const getZfs = (deviceId: string) => {
  return ZDMFetch(methodGet, getZfsUrl(workspaceId, deviceId));
};

export const getZfsFile = (deviceId: string, zfsId: string) => {
  return ZDMFetch(methodGet, getZfsFileUrl(workspaceId, deviceId, zfsId));
};

export const getJobs = (deviceId: string) => {
  return ZDMFetch(methodGet, getJobsUrl(deviceId));
};

export const checkZfsStatus = (deviceId: string) => {
  return ZDMFetch(methodGet, checkZfsStatusUrl(deviceId));
};

export const getJobStatus = (deviceId: string) => {
  return ZDMFetch(methodGet, getJobStatusUrl(workspaceId, deviceId));
};

export const getLastD = (deviceId: string) => {
  //https://api.cloud.zerynth.com/v3/timeseries/wks-8oglsckydf5t/data?size=1&from=0&device=dev-95imnumcju34&tag=b
  return ZDMFetch(methodGet, getLastDUrl(workspaceId, deviceId));
};
