import React, { useCallback, useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import { isDev, mainUrl } from "../costants";
import CustomSelect from "../../custom/CustomSelect";
import CustomDivider from "../../custom/CustomDivider";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../../custom/CustomInput";
import { getElevators, sendJob } from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import { useSnackbar } from "notistack";
import { getLastD } from "../../../api/services/configuratorService";

type TestBatteriaTamponeProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const TestBatteriaTampone: React.FC<TestBatteriaTamponeProps> = ({
  setBreadcrumb,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("bufferBatteryTest")}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    getElevators().then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoading(false);
    });
  }, []);

  const [values, setValues] = useState<any>({
    installation: "",
    elevator: "",
    enabled: true,
    batteria_tampone_giorno: "",
    batteria_tampone_ora: "",
    batteria_tampone_frequenza: "",
    duration: "",
  });

  const handleSubmit = useCallback(() => {
    sendJob(values.elevator, "disable_batt_test", {
      batt_test: values.enabled ? 0 : 1,
    });
    sendJob(values.elevator, "change_test_params", {
      hour: Number(values.batteria_tampone_ora),
      duration: Number(values.duration),
      week_day: values.batteria_tampone_giorno,
      frequency: values.batteria_tampone_frequenza,
    }).then((res: any) => {
      if (res && res.job) {
        enqueueSnackbar(t("jobSuccess"), { variant: "success" });
      } else {
        enqueueSnackbar(t("jobError"), { variant: "error" });
      }
    });
  }, [values]);

  const handleTestNow = useCallback(() => {
    sendJob(values.elevator, "local_test", {
      duration: Number(values.duration),
    }).then((res: any) => {
      if (res && res.job) {
        enqueueSnackbar(t("jobSuccess"), { variant: "success" });
      } else {
        enqueueSnackbar(t("jobError"), { variant: "error" });
      }
    });
  }, [values]);

  useEffect(() => {
    if (values.elevator) {
      getLastD(values.elevator).then((res: any) => {
        if (
          res &&
          res.result &&
          res.result.length > 0 &&
          res.result[0] &&
          res.result[0].payload
        ) {
          setValues({
            ...values,
            batteria_tampone_giorno:
              res.result[0].payload?.schedule_week_day || "",
            batteria_tampone_ora:
              String(res.result[0].payload?.test_hour) || "",
            batteria_tampone_frequenza:
              res.result[0].payload?.test_frequency || "",
            duration:
              res.result[0].payload.duration && res.result[0].payload.length > 0
                ? res.result[0].payload.duration[0]
                : "",
          });
        } else {
          setValues({
            ...values,
            batteria_tampone_giorno: "",
            batteria_tampone_ora: "",
            batteria_tampone_frequenza: "",
            duration: "",
          });
        }
      });
    }
  }, [values.elevator]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("bufferBatteryTest")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("installationName")}`}
                  value={values.installation}
                  handleChange={(e) =>
                    setValues({ ...values, installation: e.target.value })
                  }
                  options={[
                    ...elevators
                      .map((e) => {
                        return {
                          label: `${e.installation_container_name}`,
                          value: e.installation_container_name,
                        };
                      })
                      .filter((e, index) => {
                        return (
                          index ===
                          elevators.findIndex((el) => {
                            return el.installation_container_name === e.label;
                          })
                        );
                      }),
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("systemNumber")}`}
                  value={values.elevator}
                  disabled={!values.installation}
                  handleChange={(e) =>
                    setValues({ ...values, elevator: e.target.value })
                  }
                  options={[
                    ...elevators
                      .filter((e) => {
                        return (
                          e.installation_container_name === values.installation
                        );
                      })
                      .map((e) => {
                        return {
                          label: `${e.system_number} (${e.id})`,
                          value: e.id,
                        };
                      }),
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomDivider
                  label={`${t("bufferBatteryTest")}`}
                  textAlign="center"
                />
              </Grid>
              <Grid item xs={12}>
                <Switch
                  checked={values.enabled}
                  onChange={() =>
                    setValues({ ...values, enabled: !values.enabled })
                  }
                />
                Test periodico {values.enabled ? "attivato" : "disattivato"}
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  disabled={!values.enabled}
                  options={[
                    {
                      label: "Lunedì",
                      value: "lun",
                    },
                    {
                      label: "Martedì",
                      value: "mar",
                    },
                    {
                      label: "Mercoledì",
                      value: "mer",
                    },
                    {
                      label: "Giovedì",
                      value: "gio",
                    },
                    {
                      label: "Venerdì",
                      value: "ven",
                    },
                    {
                      label: "Sabato",
                      value: "sab",
                    },
                    {
                      label: "Domenica",
                      value: "dom",
                    },
                  ]}
                  label="Giorno"
                  value={values.batteria_tampone_giorno}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      batteria_tampone_giorno: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  disabled={!values.enabled}
                  options={[
                    {
                      label: "00",
                      value: "00",
                    },
                    {
                      label: "01",
                      value: "01",
                    },
                    {
                      label: "02",
                      value: "02",
                    },
                    {
                      label: "03",
                      value: "03",
                    },
                    {
                      label: "04",
                      value: "04",
                    },
                    {
                      label: "05",
                      value: "05",
                    },
                    {
                      label: "06",
                      value: "06",
                    },
                    {
                      label: "07",
                      value: "07",
                    },
                    {
                      label: "08",
                      value: "08",
                    },
                    {
                      label: "09",
                      value: "09",
                    },
                    {
                      label: "10",
                      value: "10",
                    },
                    {
                      label: "11",
                      value: "11",
                    },
                    {
                      label: "12",
                      value: "12",
                    },
                    {
                      label: "13",
                      value: "13",
                    },
                    {
                      label: "14",
                      value: "14",
                    },
                    {
                      label: "15",
                      value: "15",
                    },
                    {
                      label: "16",
                      value: "16",
                    },
                    {
                      label: "17",
                      value: "17",
                    },
                    {
                      label: "18 ",
                      value: "18",
                    },
                    {
                      label: "19",
                      value: "19",
                    },
                    {
                      label: "20",
                      value: "20",
                    },
                    {
                      label: "21",
                      value: "21",
                    },
                    {
                      label: "22",
                      value: "22",
                    },
                    {
                      label: "23",
                      value: "23",
                    },
                  ]}
                  label={`${t("hour")}`}
                  value={values.batteria_tampone_ora}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      batteria_tampone_ora: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  disabled={!values.enabled}
                  options={[
                    {
                      label: t("weekly"),
                      value: "week",
                    },
                    {
                      label: t("monthly"),
                      value: "month",
                    },
                  ]}
                  label={`${t("frequency")}`}
                  value={values.batteria_tampone_frequenza}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      batteria_tampone_frequenza: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("duration")}
                  type="number"
                  disabled={!values.enabled}
                  value={values.duration}
                  handleChange={(e: any) => {
                    setValues({ ...values, duration: e.target.value });
                  }}
                  endAdornment="s"
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  installation: "",
                  elevator: "",
                  contattore_manovra: "",
                  contattore_apertura_porte: "",
                  batteria_tampone_data: "",
                  batteria_tampone_frequenza: "",
                  note: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("immediateTest")}
              onClick={handleTestNow}
              type="contained"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
      {!isDev && (
        <Grid item xs={12}>
          <iframe
            id="grafana_iframe"
            title="grafana_iframe"
            className="responsive-iframe"
            src={`https://grafana.app.baglini.zerynth.com/d/TestBatteriaTampone/p5-testbatteriatampone?orgId=1&refresh=5s&kiosk=tv&var-device_id=${
              values && values.elevator ? values.elevator : ""
            }`}
            style={{
              height: "760px",
              display: "block",
              width: "100%",
              border: "none",
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TestBatteriaTampone;
