import React from "react";
import { capitalizedString, darkColor, lightColor } from "./utils";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

type CustomTitleProps = {
  title: string;
  subtitle?: string;
  goBack?: () => void;
};

const CustomTitle: React.FC<CustomTitleProps> = ({
  title,
  subtitle,
  goBack,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container alignItems="center" style={{ padding: "8px" }}>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={isMobile ? 1.5 : 0.5}>
          {goBack && (
            <IconButton
              size="small"
              onClick={goBack}
              style={{ backgroundColor: lightColor }}
            >
              <ArrowBack
                fontSize="small"
                htmlColor={darkColor}
                style={{ color: "#ffffff" }}
              />
            </IconButton>
          )}
        </Grid>
        <Grid xs={isMobile ? 10.5 : 11.5} item container flexDirection="column">
          <Grid item>
            <Typography variant="h5" style={{ color: darkColor }}>
              {capitalizedString(title)}
            </Typography>
          </Grid>
          {subtitle && (
            <Grid item>
              <Typography variant="subtitle2" style={{ color: darkColor }}>
                {capitalizedString(subtitle)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ borderColor: lightColor, borderWidth: "1.2px" }} />
      </Grid>
    </Grid>
  );
};

export default CustomTitle;
