import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import React from "react";
import { darkColor } from "./utils";

type CustomCardProps = {
  content: React.ReactNode;
  header?: {
    visible: boolean;
    avatar?: React.ReactNode;
    action?: React.ReactNode;
    title?: string;
    subtitle?: string;
  };
  actions?: React.ReactNode[];
  fullHeight?: boolean;
};

const CustomCard: React.FC<CustomCardProps> = ({
  content,
  header,
  actions,
  fullHeight,
}) => {
  return (
    <Card
      variant="outlined"
      key={`key-card`}
      style={{ height: fullHeight ? "100%" : undefined }}
    >
      {header && header.visible && (
        <CardHeader
          avatar={header.avatar && header.avatar}
          action={header.action && header.action}
          title={header.title && header.title}
          subheader={header.subtitle && header.subtitle}
          titleTypographyProps={{ variant: "h6" }}
          subheaderTypographyProps={{ variant: "subtitle1", color: darkColor }}
        />
      )}
      <CardContent key={`key-card-content`}>{content}</CardContent>
      {actions && (
        <CardActions style={{ justifyContent: "flex-end" }}>
          {actions.map((a: React.ReactNode, index: number) => (
            <React.Fragment key={`action-${index}`}>{a}</React.Fragment>
          ))}
        </CardActions>
      )}
    </Card>
  );
};

export default CustomCard;
