import { methodPut, verticalFetch } from "./httpRequests";
import {
  assignBoardTypeUrl,
  assignCustomerUrl,
  assignMantainerUrl,
  assignResponsibleUrl,
  changeInstallerUrl,
  unassignCustomerUrl,
  unassignMantainerUrl,
  unassignResponsibleUrl,
} from "./url";

export const assignResponsible = (
  installationId: string,
  mantainerId: string
) => {
  return verticalFetch(
    methodPut,
    assignResponsibleUrl(installationId, mantainerId)
  );
};

export const unassignResponsible = (
  installationId: string,
  mantainerId: string
) => {
  return verticalFetch(
    methodPut,
    unassignResponsibleUrl(installationId, mantainerId)
  );
};

export const assignMantainer = (
  installationId: string,
  mantainerId: string
) => {
  return verticalFetch(
    methodPut,
    assignMantainerUrl(installationId, mantainerId)
  );
};

export const unassignMantainer = (
  installationId: string,
  mantainerId: string
) => {
  return verticalFetch(
    methodPut,
    unassignMantainerUrl(installationId, mantainerId)
  );
};

export const assignCustomer = (installationId: string, customerId: string) => {
  return verticalFetch(
    methodPut,
    assignCustomerUrl(installationId, customerId)
  );
};

export const unassignCustomer = (
  installationId: string,
  customerId: string
) => {
  return verticalFetch(
    methodPut,
    unassignCustomerUrl(installationId, customerId)
  );
};

export const changeInstaller = (
  installationId: string,
  installerId: string
) => {
  return verticalFetch(
    methodPut,
    changeInstallerUrl(installationId, installerId)
  );
};

export const assignBoardType = (adminId: string, boardType: string) => {
  return verticalFetch(methodPut, assignBoardTypeUrl(adminId, boardType));
};
