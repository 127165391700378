export const idParam = ":id";

export const resetPasswordPageUrlResult = `/reset-result`;
export const recoverPageUrlResult = `/recover-result`;
export const profileUrl = `/profile`;
export const loginPageUrl = `/login`;
export const resetPasswordPageUrl = `/recover`;
export const recoverPageUrl = `/auth/recover/end`;
export const mainUrl = `*`;
export const companyListToChartUrl = `/data/dashboard/company`;
export const elevatorListToChartUrl = (id: string) =>
  `/data/dashboard/company/${id}/elevators`;
export const createCompanyUrl = `/company/new`;
export const chartPageUrl = (id: string) => `/installations/chart/${id}`;
export const alarmsPageUrl = `/alarms`;
export const downloadDataUrl = `/data/download`;
export const companyListToDownloadDataUrl = `/data/download/company`;
export const elevatorListToDownloadDataUrl = (id: string) =>
  `/data/download/company/${id}/elevator`;
export const downloadPageUrl = (id: string) => `/installations/download/${id}`;
export const companyListToUpdateUrl = `/data/update/company`;
export const updateCompanyUrl = (id: string) => `/data/update/company/${id}`;
export const paramsElevatorUrl = `/elevator/params`;
export const testBatteriaTamponeElevatorUrl = `/elevator/test-batteria-tampone`;
export const createElevatorUrl = `/elevator/new`;
export const elevatorListToUpdateUrl = `/data/update/elevator`;
export const updateElevatorUrl = (id: string) => `/data/update/elevator/${id}`;
export const configurationUrl = (id: string) =>
  `/elevator/${id}/configuration/fw`;
export const configurationEndUrl = (id: string) =>
  `/elevator/${id}/configuration/end`;
export const configurationParamsUrl = (id: string) =>
  `/elevator/${id}/configuration/params`;
export const createMantainerUrl = `/mantainer/new`;
export const mantainerListUrl = `/mantainer/list`;
export const updateMantainerUrl = (id: string) => `/mantainer/update/${id}`;
export const assignMantainerUrl = `/mantainer/assign`;
export const elevatorsHistoryUrl = `/elevator/history`;
export const createUsersUrl = `/users/new`;
export const usersListUrl = `/users/list`;
export const updateUserUrl = (id: string) => `/users/update/${id}`;
export const assignUserUrl = `/users/assign`;
export const deleteElevatorUrl = `/elevator/delete`;
export const createMaintenanceUrl = `/maintenance/new`;
export const maintenanceListUrl = `/maintenance/list`;
export const maintenanceHistoryUrl = `/maintenance/history`;
export const createAdminUrl = `/admin/new`;
export const adminListUrl = `/admin/list`;
export const assignBoardTypeUrl = `/board/assign`;
export const alarmsThresholdUrl = (id: string) => `/alarms/${id}/threshold`;
export const azMaintenanceUrl = `/elevators/all/maintenance`;
export const azUserMaintenanceUrl = `/elevators/all/maintenance/users`;
export const wifiConfigurationUrl = `/wifi/configuration`;

export const routes = {
  profile: profileUrl,
  mainPage: mainUrl,
  companyListToChart: companyListToChartUrl,
  elevatorListToChart: elevatorListToChartUrl(idParam),
  chartPage: chartPageUrl(idParam),
  createCompany: createCompanyUrl,
  login: loginPageUrl,
  recoverStart: resetPasswordPageUrl,
  recoverEnd: recoverPageUrl,
  resetResult: resetPasswordPageUrlResult,
  recoverResult: recoverPageUrlResult,
  alarms: alarmsPageUrl,
  downloadData: downloadDataUrl,
  companyListToDownloadData: companyListToDownloadDataUrl,
  elevatorListToDownloadData: elevatorListToDownloadDataUrl(idParam),
  downloadPage: downloadPageUrl(idParam),
  companyListToUpdate: companyListToUpdateUrl,
  updateCompany: updateCompanyUrl(idParam),
  paramsElevator: paramsElevatorUrl,
  testBatteriaTamponeElevator: testBatteriaTamponeElevatorUrl,
  createElevator: createElevatorUrl,
  elevatorListToUpdate: elevatorListToUpdateUrl,
  updateElevator: updateElevatorUrl(idParam),
  configuration: configurationUrl(idParam),
  configurationEnd: configurationEndUrl(idParam),
  configurationParams: configurationParamsUrl(idParam),
  createMantainer: createMantainerUrl,
  assignMantainer: assignMantainerUrl,
  elevatorsHistory: elevatorsHistoryUrl,
  updateMantainer: updateMantainerUrl(idParam),
  mantainerList: mantainerListUrl,
  createUsers: createUsersUrl,
  usersList: usersListUrl,
  updateUser: updateUserUrl(idParam),
  assignUser: assignUserUrl,
  deleteElevator: deleteElevatorUrl,
  createMaintenance: createMaintenanceUrl,
  maintenanceList: maintenanceListUrl,
  maintenanceHistory: maintenanceHistoryUrl,
  createAdmin: createAdminUrl,
  adminList: adminListUrl,
  assignBoardType: assignBoardTypeUrl,
  alarmsThreshold: alarmsThresholdUrl(idParam),
  azMaintenance: azMaintenanceUrl,
  azUserMaintenance: azUserMaintenanceUrl,
  wifiConfiguration: wifiConfigurationUrl,
};

export const roles = {
  superadmin: "superadmin",
  admin: "admin",
  installer: "installer",
  customer: "customer",
  company: "azienda",
  maintainer: "manutentore",
  respMaintainer: "responsabile",
};

export const isDev =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
