import React, { useCallback, useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { mainUrl } from "../costants";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect from "../../custom/CustomSelect";

type CreateMaintenanceProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const CreateMaintenance: React.FC<CreateMaintenanceProps> = ({
  setBreadcrumb,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("newIntervention")}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState<any>({
    note: "",
    installation: "",
    elevator: "",
    next_maintenance: "",
  });

  const handleSubmit = useCallback(() => {
    console.log("VALUES ", values);
  }, [values]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("newIntervention")}
          goBack={() => navigate(mainUrl)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={t("installationName")}
                  value={values.installation}
                  handleChange={(e) =>
                    setValues({ ...values, installation: e.target.value })
                  }
                  options={[
                    {
                      label: "Hotel 3",
                      value: "1",
                    },
                    {
                      label: "Condominio y",
                      value: "2",
                    },
                    {
                      label: "Palazzo x",
                      value: "3",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={t("systemNumber")}
                  value={values.elevator}
                  handleChange={(e) =>
                    setValues({ ...values, elevator: e.target.value })
                  }
                  options={[
                    {
                      label: "123456789",
                      value: "1",
                    },
                    {
                      label: "987654321",
                      value: "2",
                    },
                    {
                      label: "657483921",
                      value: "3",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  type="date"
                  label={t("nextMaintenance")}
                  value={values.next_maintenance}
                  handleChange={(e) =>
                    setValues({ ...values, next_maintenance: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  multiline={5}
                  label={t("note")}
                  value={values.note}
                  handleChange={(e) =>
                    setValues({ ...values, note: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  note: "",
                  installation: "",
                  elevator: "",
                  next_maintenance: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default CreateMaintenance;
